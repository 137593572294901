import UserInputApi from '../../api/userInput/userInput-api';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect, useState } from 'react';
import { useAsync } from '../useAsync';
import { AxiosError } from 'axios';
/**
 * Create user data inputs from the api asynchronously
 */
export const useCreateDataInputs = (immediate = false) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [controller] = useState(new AbortController());

  const load = useCallback(
    async (udi: any) => {
      let accessToken = undefined;
      if (isAuthenticated) {
        accessToken = await getAccessTokenSilently();
      }
      return UserInputApi.createUserDataInput(udi, accessToken, controller);
    },
    [getAccessTokenSilently, isAuthenticated, controller]
  );
  const { status, value, error, execute } = useAsync(load, immediate);

  // Abort the call if unmounting
  useEffect(() => () => controller.abort(), [controller]);

  return {
    value,
    status,
    abort: () => controller.abort(),
    execute,
    error: error as AxiosError
  };
};
