export interface ResultContextI {
  result: ResultState;
  UpdateResult: (
    scope: string,
    id: string,
    status: string,
    result: any
  ) => void;
  UpdateFullResult: (newResult: ResultState) => void;
  SetSaveResult: (newId: string) => void;
}

/**
 *
 *
 * RESULT TYPES
 *
 *
 */
export interface ResultState {
  scope_1: ScopeItem[];
  scope_2: ScopeItem[];
  scope_3: ScopeItem[];
  sequestration: ScopeItem[];
  sale_inventory: ScopeItem[];
  saved?: boolean;
  /** Unique id */
  _id?: string;
  /** User Data Input Id - links to the data used to calculate this result*/
  userDataInput?: string;
  methodology?: string;
  methodologyVersion?: number;
  date?: Date;
  uid?: string;
  total?: string;
  beefTotal?: string;
  feedlotTotal?: string;
  sheepTotal?: string;
  goatTotal?: string;
  cropTotal?: string;
}

export type ScopeItem = {
  id: string;
  name: string;
  status: string;
  categories: string[];
  result: any[];
  svg?: string;
  management_strategy: [
    {
      title?: string;
      text?: string;
      links: { name: string; url: string }[];
    }
  ];
};

export enum ResultActionE {
  UpdateResult = 'UpdateResult',
  UpdateFullResult = 'UpdateFullResult',
  SetSaveResult = 'SetSaveResult',
  Reset = 'Reset'
}

export type ResultAction =
  | {
      type: ResultActionE.UpdateResult;
      payload: { scope: string; id: string; status: string; result: any };
    }
  | {
      type: ResultActionE.UpdateFullResult;
      payload: { newResult: ResultState };
    }
  | {
      type: ResultActionE.SetSaveResult;
      payload: { newId: string };
    }
  | { type: ResultActionE.Reset }; // reset to default
