import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Grid,
  ListItem,
  ListItemText
} from '@mui/material';
import { useTheme } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import ResultContext from '../../../../contexts/ResultContext/resultContext';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { getManagementStrategy, groupManagementStrategySeries } from '../ManagementStrategy';
import LinearProgressWithLabel from '../../../../components/LinearProgressWithLabel/LinearProgressWithLabel';
import ListWrapper from '../../../../components/ListWrapper/ListWrapper';

export interface ScopeRankingProps {
  categories: string[];
  changeTab: (p1: null, p2: string) => void;
}

function ScopeRanking({ categories, changeTab }: ScopeRankingProps) {
  const theme = useTheme();
  const { result } = useContext(ResultContext);

  const [series, setSeries] = useState([]);
  const [sum, setSum] = useState(0);

  useEffect(() => {
    const series = getManagementStrategy(categories, result);
    setSeries(groupManagementStrategySeries(series, false));
    setSum(series.reduce((prev, next) => next.data > 0 ? prev + next.data : prev, 0));
  }, [categories, result]);

  return (
    <Card>
      <CardHeader
        title="Major Emissions"
        subheader={<p>Responsible for &gt; 2% of your property total.</p>}
        sx={{ textAlign: 'center' }}
      />
      <Divider />
      <ListWrapper disablePadding>
        {series
          .filter((s) => Math.round((s.data * 100) / sum) > 2)
          .slice(0, 10)
          .map((s) => (
            <span key={s.name}>
              <ListItem
                sx={{
                  color: `${theme.colors.primary.main}`,
                  '&:hover': { color: `${theme.colors.primary.dark}` }
                }}
                button
                onClick={() => changeTab(null, 'management-strategy')}
              >
                <Box sx={{ width: '80%' }}>
                  <ListItemText primary={`${s.name}`} />
                </Box>
                <Box sx={{ width: '20%' }}>
                  <LinearProgressWithLabel
                    value={Math.round((s.data * 100) / sum)}
                  />
                </Box>
              </ListItem>
              <Divider />
            </span>
          ))}
      </ListWrapper>
      <Grid container direction="row" alignItems="stretch" justifyContent="end">
        <Button
          sx={{ m: 1 }}
          size="small"
          variant="text"
          endIcon={<ArrowForwardIcon />}
          onClick={() => changeTab(null, 'management-strategy')}
        >
          See Management Strategy
        </Button>
      </Grid>
    </Card>
  );
}

export default ScopeRanking;
