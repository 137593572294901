import CarbonCalculatorApi from '../carbon-calculator-api';
import {
  UserDataInputItem,
  UserDataInputState
} from '../../contexts/UserDataInputContext/userDataInputTypes';
import { ResultStore, UserInputStore } from 'src/services/LocalDB';

/**
 * Handles calls to the remote User Data Input database, or the local database if the user is not signed in
 */
class UserInputApi {
  private resource = 'userinput';

  /**
   * Get a list of the currently authenticated users existing data inputs.
   * @param token Auth0/MyMla bearer token
   * @param controller Abort controlled to abort the request if necassary
   * @returns
   */
  getUserDataInput = async (
    id: string,
    token: string,
    controller?: AbortController
  ) => {
    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`
      };
      const response = await CarbonCalculatorApi.get(
        `/${this.resource}/${id}`,
        {
          signal: controller?.signal,
          headers
        }
      );
      return response.data as UserDataInputState;
    } else {
      const input = await UserInputStore.get(id);
      return input;
    }
  };

  /**
   * Get a list of the currently authenticated users existing data inputs.
   * @param token Auth0/MyMla bearer token
   * @param controller Abort controlled to abort the request if necassary
   * @returns
   */
  getUserDataInputList = async (
    token: string,
    controller?: AbortController
  ) => {
    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`
      };
      const response = await CarbonCalculatorApi.get(`/${this.resource}/list`, {
        signal: controller?.signal,
        headers
      });
      return response.data as UserDataInputItem[];
    } else {
      const inputs = await UserInputStore.getAll();
      const mapped = await Promise.all(
        inputs.map(async (input) => {
          // Simulate what the api does and merge some of the results into the list before returning the data
          const result = await ResultStore.getByInputDataId(input._id);
          const beefTotal = result?.beefTotal;
          const feedlotTotal = result?.feedlotTotal;
          const sheepTotal = result?.sheepTotal;
          const goatTotal = result?.goatTotal;
          const cropTotal = result?.cropTotal;
          // Dont use the `total` property of the result object, it has different rounding precision
          const total = (
            parseInt(beefTotal) +
            parseInt(feedlotTotal) +
            parseInt(sheepTotal) +
            parseInt(goatTotal) +
            parseInt(cropTotal)
          ).toString();
          return {
            year: input.year,
            date: input.date,
            _id: input._id,
            version: input.version,
            methodology_version: input.methodology_version,
            data: {
              property: {
                name: input.data.property.name
              }
            },
            beefTotal,
            feedlotTotal,
            sheepTotal,
            goatTotal,
            cropTotal,
            total
          };
        })
      );
      return mapped;
    }
  };

  /**
   * Create User data input
   */
  createUserDataInput = async (
    userDataInput: UserDataInputState,
    token: string,
    controller?: AbortController
  ) => {
    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`
      };
      const response = await CarbonCalculatorApi.post(
        `/${this.resource}`,
        {
          ...userDataInput
        },
        {
          signal: controller?.signal,
          headers
        }
      );
      return response.data.id as string;
    } else {
      // FIXME: what is the return value?
      const something = UserInputStore.add(userDataInput);
      return something;
    }
  };

  /**
   * Save User data input
   */
  saveUserDataInput = async (
    userDataInput: UserDataInputState,
    token: string,
    controller?: AbortController
  ) => {
    const { _id: inputId, ...saveData } = userDataInput;
    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      };
      const response = await CarbonCalculatorApi.put(
        `/${this.resource}/${inputId}`,
        saveData,
        {
          signal: controller?.signal,
          headers
        }
      );
      return response.data.id as string;
    } else {
      await UserInputStore.put(userDataInput);
      ResultStore.delByInputDataId(userDataInput._id);
      return inputId;
    }
  };

  /**
   * Delete user data inputs.
   * @param token Auth0/MyMla bearer token
   * @param controller Abort controlled to abort the request if necassary
   * @returns
   */
  deleteUserDataInput = async (
    id: string,
    token: string,
    controller?: AbortController
  ) => {
    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`
      };
      const response = await CarbonCalculatorApi.delete(
        `/${this.resource}/${id}`,
        {
          params: {},
          signal: controller?.signal,
          headers
        }
      );
      return response.data as boolean;
    } else {
      await UserInputStore.del(id);
      return true;
    }
  };
}

export default new UserInputApi();
