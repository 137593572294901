import { createContext, useCallback, useReducer } from 'react';

import {
  defaultResultState,
  resultReducer,
} from './resultReducers';

import {
  ResultActionE, ResultContextI, ResultState
} from './resultTypes';

// react 18 as no more children in default React.Component
interface ResultContextProps {
  children: React.ReactNode;
}

const defaultFunction = () => {
  throw new Error('Function not implemented');
};

const defaultResultContext: ResultContextI = {
  UpdateResult: defaultFunction,
  UpdateFullResult: defaultFunction,
  SetSaveResult: defaultFunction,
  result: defaultResultState,
};
const ResultContext = createContext<ResultContextI>(defaultResultContext);

export const ResultContextProvider: React.FC<ResultContextProps> = ({ children }) => {
  const [state, dispatch] = useReducer(resultReducer, defaultResultState);

  const UpdateResult = useCallback((scope: string, id: string, status: string, result: any) => {
    dispatch({
      type: ResultActionE.UpdateResult,
      payload: { scope, id, status, result },
    });
  }, []);

  const UpdateFullResult = useCallback((newResult: ResultState) => {
    dispatch({
      type: ResultActionE.UpdateFullResult,
      payload: { newResult },
    });
  }, []);

  const SetSaveResult = useCallback((newId: string) => {
    dispatch({
      type: ResultActionE.SetSaveResult,
      payload: { newId },
    });
  }, []);

  return (
    <ResultContext.Provider
      value={{
        UpdateResult,
        UpdateFullResult,
        SetSaveResult,
        result: state,
      }}
    >
      {children}
    </ResultContext.Provider>
  );
};

export default ResultContext;
