import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router';

const MyMlaProvider = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENTID}
      authorizationParams={{
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        redirect_uri: `${window.location.origin}/callback`
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default MyMlaProvider;
