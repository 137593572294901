import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { useAuth0 } from '@auth0/auth0-react';
import Loading from './content/Status/Loading';

function App() {
  const content = useRoutes(router);
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {content}
    </LocalizationProvider>
  );
}
export default App;
