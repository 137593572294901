import useCallbackHandler from 'src/hooks/integration/useCallbackHandler';
import Loading from '../Status/Loading';

/**
 * Page that handles external integations.
 */
const Integration = () => {
  useCallbackHandler();
  return <Loading />;
};

export default Integration;
