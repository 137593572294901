import {
  Alert, Box, Button,
  Card, Checkbox, CircularProgress, Dialog, Divider,
  Grid, Skeleton,
  Tab,
  Table,
  TableBody,
  TableCell, TableContainer,
  TableHead,
  TableRow,
  Tabs, Typography
} from '@mui/material';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import ResultContext from '../../../../contexts/ResultContext/resultContext';
import ScopeTable from '../ScopeTable';
import { styled } from '@mui/material/styles';
import TableViewIcon from '@mui/icons-material/TableView';
import Co2Icon from '@mui/icons-material/Co2';
import PieChartIcon from '@mui/icons-material/PieChart';
import PsychologyIcon from '@mui/icons-material/Psychology';
import useIsInViewport from '../../../../hooks/useIsInViewport';
import SummaryTable from '../SummaryTable';
import BreakdownPerSection from '../BreakdownPerSection';
import BreakdownPerGhg from '../BreakdownPerGhg';
import ScopeRanking from '../ScopeRanking';
import { useParams } from 'react-router';
import EmissionsIntensity from '../EmissionsIntensity';
import ManagementStrategy from '../ManagementStrategy';
import { precisionOrBlank } from 'src/utils';
import UserDataInputContext from '../../../../contexts/UserDataInputContext/userDataInputContext';
import { Link } from 'react-router-dom';
import { useDataInputs } from '../../../../hooks';
import { UserDataInputItem } from '../../../../contexts/UserDataInputContext/userDataInputTypes';
import { useAuth0 } from '@auth0/auth0-react';
import ResultApi from '../../../../api/result/result-api';
import { ResultState, ScopeItem } from '../../../../contexts/ResultContext/resultTypes';
import PdfModal from '../PdfModal';

const TabsWrapper = styled(Tabs)(
  () => `
    .MuiTabs-scrollableX {
      overflow-x: auto !important;
    }
`
);

function CombineResults() {
  const { tab } = useParams();
  const { userDataInput } = useContext(UserDataInputContext);
  const { result } = useContext(ResultContext);
  const [isLoading, setIsloading]  = useState(false);
  const [filteredUdis, setFilteredUdis]  = useState<UserDataInputItem[]>([]);
  const [selectedUdis, setSelectedUdis]  = useState<string[]>([]);
  const [combineUserDataInput, setCombineUserDataInput]  = useState<any>(userDataInput);
  const [combineResult, setCombineResults] = useState(null);
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [controller] = useState(new AbortController());
  const [openPdfModal, setOpenPdfModal] = useState(false);


  const {
    userDataInputs,
    status: dataStatus,
    error: dataError,
    execute: execList
  } = useDataInputs();

  const getResult = useCallback(async (udi_id: string) => {
    let accessToken: string;
    if (isAuthenticated) {
      accessToken = await getAccessTokenSilently();
    }
    return ResultApi.getResult(udi_id, accessToken, controller);
  }, [controller, getAccessTokenSilently, isAuthenticated]);

  // trick to filter list of udis as typescript freak out on the .filter()
  useEffect(() => {
    // @ts-ignore
    setFilteredUdis(userDataInputs.filter((udi) =>
      udi.year === userDataInput.year && (
        udi.beefTotal !== undefined
        || udi.feedlotTotal !== undefined
        || udi.sheepTotal !== undefined
        || udi.cropTotal !== undefined
        || udi.goatTotal !== undefined)));
  }, [userDataInput.year, userDataInputs]);

  useEffect(() => {
    setIsloading(true);
    let cUdi = {
      _id: userDataInput._id,
      data: {
        property: {
          is_beef_producer: userDataInput.data.property.is_beef_producer,
          is_feedlot_producer: userDataInput.data.property.is_feedlot_producer,
          is_sheep_producer: userDataInput.data.property.is_sheep_producer,
          is_crop_producer: userDataInput.data.property.is_crop_producer,
          is_goat_producer: userDataInput.data.property.is_goat_producer,
        }
      }
    };
    const promises = [];
    selectedUdis.forEach((selected) => {
      promises.push(getResult(selected));
      // @ts-ignore
      if(!cUdi.data.property.is_beef_producer && userDataInputs.find((udi) => udi._id === selected)?.beefTotal) cUdi.data.property.is_beef_producer = true;
      // @ts-ignore
      if(!cUdi.data.property.is_feedlot_producer && userDataInputs.find((udi) => udi._id === selected)?.feedlotTotal) cUdi.data.property.is_feedlot_producer = true;
      // @ts-ignore
      if(!cUdi.data.property.is_sheep_producer && userDataInputs.find((udi) => udi._id === selected)?.sheepTotal) cUdi.data.property.is_sheep_producer = true;
      // @ts-ignore
      if(!cUdi.data.property.is_crop_producer && userDataInputs.find((udi) => udi._id === selected)?.cropTotal) cUdi.data.property.is_crop_producer = true;
      // @ts-ignore
      if(!cUdi.data.property.is_goat_producer && userDataInputs.find((udi) => udi._id === selected)?.goatTotal) cUdi.data.property.is_goat_producer = true;
    });
    let cResults = {
      scope_1: result.scope_1,
      scope_2: result.scope_2,
      scope_3: result.scope_3,
      sequestration: result.sequestration,
      sale_inventory: result.sale_inventory,
      beefTotal: result.beefTotal,
      feedlotTotal: result.feedlotTotal,
      sheepTotal: result.sheepTotal,
      goatTotal: result.goatTotal,
      cropTotal: result.cropTotal,
    };
    const reduceScope = (merge: ScopeItem[]) => {
      return merge.reduce((acc, current) => {
        const index = acc.findIndex((scope) => scope.id === current.id);
        if(index === -1)
          return [...acc, current];
        else {
          let existing = acc[index] as ScopeItem;
          // reducing the different result object of the scope
          let newResult = [...existing.result, ...current.result].reduce((rAcc, rCurrent) => {
            const rIndex = rAcc.findIndex((res) =>  res.id === rCurrent.id);
            if (rIndex === -1)
              return [...rAcc, rCurrent];
            else {
              // summing up if using same key
              let sum = rAcc[rIndex].result + rCurrent.result;
              let rAccCopy = [...rAcc];
              rAccCopy.splice(rIndex, 1, { id: rCurrent.id, result: sum});
              return rAccCopy;
            }
          }, []);
          let accCopy = [...acc];
          accCopy.splice(index, 1, {
            id: current.id,
            name: current.name,
            status: current.status,
            categories: current.categories,
            result: newResult,
            svg: current.svg,
            management_strategy: current.management_strategy
          });
          return accCopy;
        }
      }, []);
    }
    Promise.all(promises).then((combine: ResultState[]) => {
      combine.forEach((r) => {
        cResults.beefTotal = (parseInt(cResults.beefTotal || '0') + parseInt(r.beefTotal || '0')).toString();
        cResults.feedlotTotal = (parseInt(cResults.feedlotTotal || '0') + parseInt(r.feedlotTotal || '0')).toString();
        cResults.sheepTotal = (parseInt(cResults.sheepTotal || '0') + parseInt(r.sheepTotal || '0')).toString();
        cResults.goatTotal = (parseInt(cResults.goatTotal || '0') + parseInt(r.goatTotal || '0')).toString();
        cResults.cropTotal = (parseInt(cResults.cropTotal || '0') + parseInt(r.cropTotal || '0')).toString();
        cResults.scope_1 = reduceScope([...cResults.scope_1, ...r.scope_1]);
        cResults.scope_2 = reduceScope([...cResults.scope_2, ...r.scope_2]);
        cResults.scope_3 = reduceScope([...cResults.scope_3, ...r.scope_3]);
        cResults.sequestration = reduceScope([...cResults.sequestration, ...r.sequestration]);
        cResults.sale_inventory = reduceScope([...cResults.sale_inventory, ...r.sale_inventory]);
      })
      setCombineUserDataInput(cUdi);
      setCombineResults(cResults);
      setIsloading(false);
    });
// eslint-disable-next-line
  }, [selectedUdis]);

  const handleSelect = (value: string) => {
    if(selectedUdis.includes(value))
      setSelectedUdis(selectedUdis.filter((id) => id !== value))
    else
      setSelectedUdis([...selectedUdis, value])
  }

  const handleOpenPdfModal = () => setOpenPdfModal(true);

  const handleClosePdfModal = () => setOpenPdfModal(false);

  const tabs = useMemo(
    () => [
      {
        value: 'results-table',
        label: 'Results table',
        icon: <TableViewIcon />
      },
      {
        value: 'emissions-intensity',
        label: 'Emissions intensity',
        icon: <Co2Icon />
      },
      {
        value: 'ghg-breakdown',
        label: 'Breakdown',
        icon: <PieChartIcon />
      },
      {
        value: 'management-strategy',
        label: 'Management Strategy',
        icon: <PsychologyIcon />
      }
    ],
    []
  );

  const [currentTab, setCurrentTab] = useState<string>(
    tabs.find((t) => t.value === tab) ? tab : 'results-table'
  );
  const tabwrapperRef = useRef(null);
  const isTabWrapperInViewport = useIsInViewport(tabwrapperRef);

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    window.history.pushState(null, '', `./` + value);
    setCurrentTab(value);
  };

  const categories = useMemo(() => {
    const cat = [];
    if (combineUserDataInput.data?.property.is_beef_producer) cat.push('beef');
    if (combineUserDataInput.data?.property.is_feedlot_producer) cat.push('feedlot');
    if (combineUserDataInput.data?.property.is_sheep_producer) cat.push('sheep');
    if (combineUserDataInput.data?.property.is_goat_producer) cat.push('goat');
    if (combineUserDataInput.data?.property.is_crop_producer) cat.push('crop');
    return cat;
  }, [combineUserDataInput]);

  const enterpriseTotal = useCallback(() => {
    return precisionOrBlank(
      combineResult?.scope_1.reduce(
        (prev: number, current: any) =>
          prev +
          (current.result?.reduce(
            (p: number, c: any) => p + (!categories.includes(c.id)  ? 0 : c.result || 0),
            0
          ) || 0),
        0
      ) +
      combineResult?.scope_2.reduce(
        (prev: number, current: any) =>
          prev +
          (current.result?.reduce(
            (p: number, c: any) => p + (!categories.includes(c.id)  ? 0 : c.result || 0),
            0
          ) || 0),
        0
      ) +
      combineResult?.scope_3.reduce(
        (prev: number, current: any) =>
          prev +
          (current.result?.reduce(
            (p: number, c: any) => p + (!categories.includes(c.id)  ? 0 : c.result || 0),
            0
          ) || 0),
        0
      ) +
      combineResult?.sequestration.reduce(
        (prev: number, current: any) =>
          prev +
          (current.result?.reduce(
            (p: number, c: any) => p + (!categories.includes(c.id)  ? 0 : c.result || 0),
            0
          ) || 0),
        0
      )
    );
  }, [combineResult, categories]);

  useEffect(() => {
    setCurrentTab(tabs.find((t) => t.value === tab) ? tab : 'results-table');
  }, [tab, tabs]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      spacing={3}
    >
      <Grid item xs={9}>
        <Alert
          severity={'success'}
          action={
            <>
              <Button
                size="small"
                disabled={isLoading}
                onClick={handleOpenPdfModal}
              >
                PDF
              </Button>
              <Link to={'../results'}>
                <Button size="small">
                  Back to single Results
                </Button>
              </Link>
            </>
          }
        >
          Displaying <b>combine</b> result of <b>{isLoading ? <CircularProgress size='1em' /> : selectedUdis.length + 1}</b> calculations with a total of <b>{enterpriseTotal()} t CO2</b>
        </Alert>
        <Grid mt={1} mb={2}>
          {userDataInputs.length === 0 && dataStatus === 'pending' ? (
            <Skeleton variant="rectangular" height={118} />
          ) : !dataError ? (
            <Card>
              <Divider />
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Property name</TableCell>
                      <TableCell align="right">
                        <Box>Beef</Box>
                      </TableCell>
                      <TableCell align="right">
                        <Box>Feedlot</Box>
                      </TableCell>
                      <TableCell align="right">
                        <Box>Sheep</Box>
                      </TableCell>
                      <TableCell align="right">
                        <Box>Goat</Box>
                      </TableCell>
                      <TableCell align="right">
                        <Box>Crop</Box>
                      </TableCell>
                      <TableCell align="center">Combine</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredUdis.map((userData) => {
                      return (
                        <TableRow hover key={userData._id} selected={false}>
                          <TableCell>
                            <Typography
                              variant="body1"
                              fontWeight="bold"
                              color="text.primary"
                              gutterBottom
                              noWrap
                            >
                              {userData.data.property?.name}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              noWrap
                            >
                              {new Date(userData.date).toDateString()}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography
                              variant="body1"
                              color="text.primary"
                              gutterBottom
                              noWrap
                            >
                              {userData.beefTotal || '-'}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              noWrap
                            >
                              t -eCO2
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography
                              variant="body1"
                              color="text.primary"
                              gutterBottom
                              noWrap
                            >
                              {userData.feedlotTotal || '-'}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              noWrap
                            >
                              t -eCO2
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography
                              variant="body1"
                              color="text.primary"
                              gutterBottom
                              noWrap
                            >
                              {userData.sheepTotal || '-'}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              noWrap
                            >
                              t -eCO2
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography
                              variant="body1"
                              color="text.primary"
                              gutterBottom
                              noWrap
                            >
                              {userData.goatTotal || '-'}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              noWrap
                            >
                              t -eCO2
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography
                              variant="body1"
                              color="text.primary"
                              gutterBottom
                              noWrap
                            >
                              {userData.cropTotal || '-'}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              noWrap
                            >
                              t -eCO2
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {userData._id === userDataInput._id ?
                              <Checkbox checked disabled /> :
                              <Checkbox
                                checked={selectedUdis.includes(userData._id)}
                                onClick={() => handleSelect(userData._id)}
                              />}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          ) : (
            <Alert color="error">
              Error while loading calculations.
              <Button color="secondary" variant="text" onClick={() => execList()}>
                Retry
              </Button>
            </Alert>
          )}
        </Grid>
        <Grid sx={{ mt: 1 }} textAlign={'center'}>
          {isLoading ? <CircularProgress sx={{ mt: 2 }} /> :
            <SummaryTable
              result={combineResult}
              categories={categories}
              enterpriseTotal={enterpriseTotal()}
              isLoaded={true}
            />
          }
        </Grid>
      </Grid>
      <Grid item xs={9}>
        <TabsWrapper
          ref={tabwrapperRef}
          onChange={handleTabsChange}
          value={currentTab}
          variant="scrollable"
          scrollButtons="auto"
          textColor="primary"
          indicatorColor="primary"
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value}
              icon={tab.icon}
              iconPosition="start"
            />
          ))}
        </TabsWrapper>
      </Grid>
      {currentTab === 'results-table' && (
        <Grid item xs={9}>
          <Card>
            {/* SCOPE 1 */}
            <ScopeTable
              title="Scope 1"
              scopes={combineResult?.scope_1 || []}
              categories={categories}
            />
          </Card>
          <Card sx={{ mt: 2 }}>
            {/* SCOPE 2 */}
            <ScopeTable
              title="Scope 2"
              scopes={combineResult?.scope_2 || []}
              categories={categories}
            />
          </Card>
          <Card sx={{ mt: 2 }}>
            {/* SCOPE 3 */}
            <ScopeTable
              title="Scope 3"
              scopes={combineResult?.scope_3 || []}
              categories={categories}
            />
          </Card>
          <Card sx={{ mt: 2 }}>
            {/* SEQUESTRATION */}
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Carbon Sequestration
                    <br />t CO₂e/farm
                  </TableCell>
                  {categories.map((c) => (
                    <TableCell key={c}>{c}</TableCell>
                  ))}
                  <TableCell>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {combineResult?.sequestration.map((scope: any) => (
                  <TableRow key={scope.id}>
                    <TableCell>
                      <b>{scope.name}</b>
                    </TableCell>
                    {categories.map((cat: string) => (
                      <TableCell key={cat + scope.id}>
                        {precisionOrBlank(
                          combineResult?.sequestration
                            .find((s) => s.id === scope.id)
                            ?.result?.find((r: any) => r.id === cat)?.result
                        )}
                      </TableCell>
                    ))}
                    <TableCell>
                      <b>
                        {`${scope.categories.includes('out') ? '(' : ''}
                        ${precisionOrBlank(
                          combineResult?.sequestration
                            .find((s) => s.id === scope.id)
                            ?.result?.reduce(
                              (prev: number, r: any) =>
                                prev + (r.result || 0),
                              0
                            )
                        )} ${scope.categories.includes('out') ? ')' : ''}`}
                      </b>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={categories.length + 2}>
                    <Alert severity={'info'}>
                      <i>Other sequestrations results are for your information only and do not go towards your carbon emissions balance.
                      These data points are not included in the GAF tools.</i>
                    </Alert>
                  </TableCell>
                </TableRow>
                {/*/!* TOTAL *!/*/}
                {/*<TableRow>*/}
                {/*  <TableCell><b>Total</b></TableCell>*/}
                {/*  { categories.map((cat: string) => (*/}
                {/*    <TableCell key={cat + 'Total'}>*/}
                {/*      {result?.sequestration?.findIndex((item: any) => item.status === 'waiting') === -1 ? precisionOrBlank(result?.sequestration.reduce((prev: number, current: any) => prev + (current.result?.find((r: any) => r.id === cat)?.result || 0), 0)) : <Skeleton variant='text' animation={false} /> }*/}
                {/*    </TableCell>*/}
                {/*  )) }*/}
                {/*  <TableCell>*/}
                {/*    <b>*/}
                {/*      {result?.sequestration?.findIndex((item: any) => item.status === 'waiting') === -1 ? precisionOrBlank(result?.sequestration.reduce((prev: number, current: any) => prev + (current.result?.reduce((p: number, c: any) => p + (c.result || 0), 0) || 0), 0)) : <Skeleton variant='text' animation={false} /> }*/}
                {/*    </b>*/}
                {/*  </TableCell>*/}
                {/*</TableRow>*/}
              </TableBody>
            </Table>
          </Card>
          <Card sx={{ mt: 2 }}>
            {/* ENTREPRISE TOTAL */}
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Enterprise Emissions
                    <br />t CO₂e/farm
                  </TableCell>
                  {categories.map((c) => (
                    <TableCell key={c}>{c}</TableCell>
                  ))}
                  <TableCell>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* TOTAL */}
                <TableRow>
                  <TableCell>
                    <b>Total</b>
                  </TableCell>
                  {categories.map((cat: string) => (
                    <TableCell key={cat + 'Total'}>
                      { precisionOrBlank(
                        combineResult?.scope_1.reduce(
                          (prev: number, current: any) =>
                            prev +
                            (current.result?.find((r: any) => r.id === cat)
                              ?.result || 0),
                          0
                        ) +
                        combineResult?.scope_2.reduce(
                            (prev: number, current: any) =>
                              prev +
                              (current.result?.find((r: any) => r.id === cat)
                                ?.result || 0),
                            0
                          ) +
                        combineResult?.scope_3.reduce(
                            (prev: number, current: any) =>
                              prev +
                              (current.result?.find((r: any) => r.id === cat)
                                ?.result || 0),
                            0
                          ) +
                        combineResult?.sequestration.reduce(
                            (prev: number, current: any) =>
                              prev +
                              (current.result?.find((r: any) => r.id === cat)
                                ?.result || 0),
                            0
                          )
                      )}
                    </TableCell>
                  ))}
                  <TableCell>
                    <b>{enterpriseTotal()}</b>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Card>
        </Grid>
      )}
      {currentTab === 'emissions-intensity' && (
        <Grid item xs={9}>
          <EmissionsIntensity result={combineResult} categories={categories} />
        </Grid>
      )}
      {currentTab === 'ghg-breakdown' && (
        <Grid
          container
          direction="row"
          alignItems="stretch"
          spacing={3}
          item
          xs={9}
        >
          <Grid item xs={6}>
            <BreakdownPerGhg result={combineResult} categories={categories} />
          </Grid>
          <Grid item xs={6}>
            <ScopeRanking
              categories={categories}
              changeTab={handleTabsChange}
            />
          </Grid>
          <Grid item xs={12}>
            <BreakdownPerSection result={combineResult} categories={categories} />
          </Grid>
        </Grid>
      )}
      {currentTab === 'management-strategy' && (
        <Grid item xs={9}>
          <ManagementStrategy result={combineResult} categories={categories} />
        </Grid>
      )}
      {!isTabWrapperInViewport && (
        <Grid item xs={9}>
          <TabsWrapper
            onChange={handleTabsChange}
            value={currentTab}
            variant="scrollable"
            scrollButtons="auto"
            textColor="primary"
            indicatorColor="primary"
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
                icon={tab.icon}
                iconPosition="start"
              />
            ))}
          </TabsWrapper>
        </Grid>
      )}
      <Dialog
        open={openPdfModal}
        onClose={handleClosePdfModal}
        fullWidth={true}
        maxWidth={'sm'}
      >
        <PdfModal
          result={combineResult}
          enterpriseTotal={enterpriseTotal()}
          categories={categories}
          handleClose={handleClosePdfModal}
        />
      </Dialog>
    </Grid>
  );
}

export default CombineResults;
