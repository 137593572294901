import { useEffect, useCallback, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAsync } from './useAsync';
import { AxiosError } from 'axios';

export const useAuthenticatedApi: <T extends any>(
  apiCall: (accessToken: string, controller: AbortController) => Promise<T>,
  immediate?: boolean
) => ReturnType<typeof useAsync> & { value: T; abort: () => void } = (
  apiCall,
  immediate = true
) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [controller] = useState(new AbortController());

  const load = useCallback(async () => {
    let accessToken = undefined;
    if (isAuthenticated) {
      accessToken = await getAccessTokenSilently();
    }
    return apiCall(accessToken, controller);
  }, [getAccessTokenSilently, apiCall, isAuthenticated, controller]);
  const { status, value, error, execute } = useAsync(load, immediate);

  // Abort the call if unmounting
  useEffect(() => () => controller.abort(), [controller]);

  return {
    value,
    status,
    abort: () => controller.abort(),
    execute,
    error: error as AxiosError
  };
};
