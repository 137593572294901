import { useCallback, useEffect, useState } from 'react';

type AsyncStatus = 'idle' | 'pending' | 'success' | 'error';

// Hook
/**
 *
 * @param asyncFunction
 * @param immediate
 * @param input
 * @returns
 */
const useAsync: <T, I extends any[], E extends Error>(
  asyncFunction: (...args: I) => Promise<T>,
  immediate: boolean,
  args?: Parameters<typeof asyncFunction>
) => {
  execute: (...args: Parameters<typeof asyncFunction>) => Promise<void>;
  status: AsyncStatus;
  value: T | null;
  error: E | null;
  // eslint-disable-next-line @typescript-eslint/default-param-last
} = (asyncFunction, immediate = true, args) => {
  const [status, setStatus] = useState<AsyncStatus>('idle');
  const [value, setValue] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  // The execute function wraps asyncFunction and
  // handles setting state for pending, value, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if asyncFunction changes.
  const execute = useCallback(
    async (...input: Parameters<typeof asyncFunction>) => {
      setStatus('pending');
      // setValue(null);
      setError(null);
      return asyncFunction(...input)
        .then((response) => {
          setValue(response);
          setStatus('success');
        })
        .catch((err) => {
          setError(err);
          // console.warn('ASYNC ERROR:', err);
          setStatus('error');
        });
    },
    [asyncFunction]
  );
  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as
  // in an onClick handler.
  useEffect(() => {
    if (immediate && status === 'idle') {
      // @ts-ignore
      execute(...((args as Parameters<typeof asyncFunction>) ?? []));
    }
  }, [execute, immediate, args, status]);
  return { execute, status, value, error };
};

export { useAsync };
