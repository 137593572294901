import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import { useMemo, useState } from 'react';
import { Snackbar, Typography, Button, styled, lighten } from '@mui/material';

const ReturnButton = styled(Button)({
  color: 'white',
  backgroundColor: '#002A42',
  ':hover': {
    backgroundColor: lighten('#002A42', 0.5)
  }
});

const Logo = styled('img')({
  padding: '1em'
});

export default function useExternalCallback() {
  const [url, setUrl] = useState(() => {
    const storedUrl = localStorage.getItem('ext-callback');
    let callbackUrl: URL | null = null;
    if (storedUrl !== null) {
      callbackUrl = new URL(storedUrl);
    }
    return callbackUrl;
  });

  const setParam = (key: string, value: string) => {
    setUrl((oldUrl) => {
      let newUrl = new URL(oldUrl.href);
      newUrl[key] = value;
      return newUrl;
    });
  };

  const redirect = () => {
    localStorage.removeItem('ext-callback');
    localStorage.removeItem('ext-logo');
    window.location.href = url.href;
  };

  const logoUri = useMemo(() => localStorage.getItem('ext-logo'), []);

  const RedirectSnack = ({
    onClick
  }: {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
  }) => (
    <Snackbar
      sx={{ width: '100%' }}
      open={url !== null}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <ReturnButton onClick={onClick}>
        <OpenInBrowserIcon sx={{ margin: 1 }} />
        <Typography variant="button" fontSize="1em">
          Press here to return to Environmental Credentials
        </Typography>
        {logoUri && <Logo src={logoUri} alt="callback-logo" />}
      </ReturnButton>
    </Snackbar>
  );

  return {
    /**
     * Url that a user will redirect to
     */
    url,
    /**
     * Set additional params in the url that will be used to redirect
     */
    setParam,
    /**
     * Function that will redirect a user back to the site that redirected them here
     */
    redirect,
    /**
     * Component that hovers at the bottom of the page if a user was redirect to this site
     * @returns Clickable snackbar
     */
    RedirectSnack
  };
}
