import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  ListItem,
  ListItemText,
  Typography,
  useTheme
} from '@mui/material';
import { precisionOrBlank } from '../../../../utils';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { useContext, useEffect, useState } from 'react';
import UserDataInputContext from '../../../../contexts/UserDataInputContext/userDataInputContext';
import MethodologyContext from '../../../../contexts/MethodologyContext/methodologyContext';
import { getEmissionIntensity } from '../EmissionsIntensity';
import { getTotalPerCategory } from '../SummaryTable';
import { getManagementStrategy, groupManagementStrategySeries } from '../ManagementStrategy';
import LinearProgressWithLabel from '../../../../components/LinearProgressWithLabel/LinearProgressWithLabel';
import ListWrapper from '../../../../components/ListWrapper/ListWrapper';
import { ResultState } from '../../../../contexts/ResultContext/resultTypes';

export interface PdfModalProps {
  result: ResultState;
  enterpriseTotal: string;
  categories: string[];
  handleClose: () => void;
}

function PdfModal({
  result,
  enterpriseTotal,
  categories,
  handleClose
}: PdfModalProps) {
  const theme = useTheme();
  const { userDataInput } = useContext(UserDataInputContext);
  const { methodology } = useContext(MethodologyContext);
  const [series, setSeries] = useState([]);
  const [sum, setSum] = useState(0);
  const [selected, setSelected] = useState([]);


  const pdf = async () => {
    const headers = [['Scope', ...categories, 'Total']];
    let remainingHeight = 0;

    const generateData = function () {
      const pdfResult: any[] = [];
      pdfResult.push(['Scope 1', ...categories.map(() => ''), '']);
      result?.scope_1.forEach((scope) => {
        pdfResult.push([
          scope.name.replace('₂', '2').replace('₄', '4'),
          ...categories.map((c) => precisionOrBlank(scope.result.find((r) => r.id === c)?.result)),
          precisionOrBlank(
            (scope.result.find((r) => r.id === 'beef')?.result || 0)
            + (scope.result.find((r) => r.id === 'feedlot')?.result || 0)
            + (scope.result.find((r) => r.id === 'sheep')?.result || 0)
            + (scope.result.find((r) => r.id === 'goat')?.result || 0)
            + (scope.result.find((r) => r.id === 'crop')?.result || 0)
          )
        ]);
      });
      pdfResult.push([]);
      pdfResult.push(['Scope 2', ...categories.map(() => ''), '']);
      result?.scope_2.forEach((scope) => {
        pdfResult.push([
          scope.name.replace('₂', '2').replace('₄', '4'),
          ...categories.map((c) => precisionOrBlank(scope.result.find((r) => r.id === c)?.result)),
          precisionOrBlank(
            (scope.result.find((r) => r.id === 'beef')?.result || 0)
            + (scope.result.find((r) => r.id === 'feedlot')?.result || 0)
            + (scope.result.find((r) => r.id === 'sheep')?.result || 0)
            + (scope.result.find((r) => r.id === 'goat')?.result || 0)
            + (scope.result.find((r) => r.id === 'crop')?.result || 0)
          )
        ]);
      });
      pdfResult.push([]);
      pdfResult.push(['Scope 3', ...categories.map(() => ''), '']);
      result?.scope_3.forEach((scope) => {
        pdfResult.push([
          scope.name.replace('₂', '2').replace('₄', '4'),
          ...categories.map((c) => precisionOrBlank(scope.result.find((r) => r.id === c)?.result)),
          precisionOrBlank(
            (scope.result.find((r) => r.id === 'beef')?.result || 0)
            + (scope.result.find((r) => r.id === 'feedlot')?.result || 0)
            + (scope.result.find((r) => r.id === 'sheep')?.result || 0)
            + (scope.result.find((r) => r.id === 'goat')?.result || 0)
            + (scope.result.find((r) => r.id === 'crop')?.result || 0)
          )
        ]);
      });
      pdfResult.push([]);
      pdfResult.push(['Sequestration', ...categories.map(() => ''), '']);
      result?.sequestration.forEach((scope) => {
        pdfResult.push([
          scope.name.replace('₂', '2').replace('₄', '4'),
          ...categories.map((c) => precisionOrBlank(scope.result.find((r) => r.id === c)?.result)),
          precisionOrBlank(
            (scope.result.find((r) => r.id === 'beef')?.result || 0)
            + (scope.result.find((r) => r.id === 'feedlot')?.result || 0)
            + (scope.result.find((r) => r.id === 'sheep')?.result || 0)
            + (scope.result.find((r) => r.id === 'goat')?.result || 0)
            + (scope.result.find((r) => r.id === 'crop')?.result || 0)
          )
        ]);
      });
      pdfResult.push([]);
      pdfResult.push(['Emissions Intensity (excl sq)']);
      if(categories.includes('beef') ||categories.includes('feedlot') ||categories.includes('sheep') || categories.includes('goat')) {
        pdfResult.push([
          'Meat',
          ...categories.map((c) => getEmissionIntensity(result, methodology, c, 'meat', true) ?
            getEmissionIntensity(result, methodology, c, 'meat', true) +
            ' (' + getEmissionIntensity(result, methodology, c, 'meat', false) + ')' : ''),
          'kg co2e / kg LW'
        ]);
      }
      if(categories.includes('sheep') || categories.includes('goat')) {
        pdfResult.push([
          'Wool',
          ...categories.map((c) => getEmissionIntensity(result, methodology, c, 'wool', true) ?
            getEmissionIntensity(result, methodology, c, 'wool', true) +
            ' (' + getEmissionIntensity(result, methodology, c, 'wool', false) + ')' : ''),
          'kg co2e / kg Greasy'
        ]);
      }
      if(categories.includes('crop')){
        pdfResult.push([
          'Crop',
          ...categories.map((c) => getEmissionIntensity(result, methodology, c, 'crop', true) ?
            getEmissionIntensity(result, methodology, c, 'crop', true) +
            ' (' + getEmissionIntensity(result, methodology, c, 'crop', false) + ')' : ''),
          't co2e / t'
        ]);
      }
      pdfResult.push([]);
      pdfResult.push([
        'Total',
      ...categories.map((c) => getTotalPerCategory(result, c, true) + ' t'),
        enterpriseTotal + ' ' + 't co2e'
      ]);
      return pdfResult;
    };

    const addHeader = (pdf: jsPDF) => {
      // add Mla logo
      pdf.addImage(
        '/static/images/logo/MLA_color_logo.png',
        'JPEG',
        170,
        5,
        30,
        17.5,
        'MLA-logo',
        'FAST'
      );
      pdf.setFontSize(10);
      pdf.setFont(undefined, 'bold');
      pdf.text('Report: ', 10, 10);
      pdf.text('Methodology:', 10, 15);
      pdf.text('Date:', 10, 20);
      pdf.text(
        `${userDataInput.data.property.name} - ${userDataInput.year}`,
        40,
        10
      );
      pdf.setFont(undefined, 'normal');
      pdf.text(
        `MLA v${methodology.version} - SB v${methodology.sbgaf} - F v${methodology.fgaf} - Go v${methodology.gogaf} - G v${methodology.ggaf}`,
        40,
        15
      );
      pdf.text(new Date().toLocaleDateString('en-GB'), 40, 20);
    };

    const addFooter = (pdf: jsPDF) => {
      pdf.setLineWidth(0.3);
      pdf.line(50, 285, 165, 285);
      pdf.setFontSize(7);
      pdf.setFont(undefined, 'normal');
      pdf.setTextColor('black');
      pdf.text(
        'Reports are prepared using data supplied by producers. MLA cannot guarantee the accuracy of this data.',
        50,
        289
      );
      pdf.text(
        '© 2023 Meat & Livestock Australia Limited ABN 39 081 678 364',
        70,
        293
      );
    };
    const pdf = new jsPDF('portrait');

    const addPageAndHeader = (pdf: jsPDF) => {
      addFooter(pdf);
      pdf.addPage('a4');
      // add Mla logo
      pdf.addImage(
        '/static/images/logo/MLA_color_logo.png',
        'JPEG',
        170,
        5,
        30,
        17.5,
        'MLA-logo',
        'FAST'
      );
      remainingHeight = 280;
    };

    // page 1
    addHeader(pdf);
    autoTable(pdf, {
      head: headers,
      body: generateData(),
      startY: 25,
      headStyles: { fillColor: [199, 199, 199], textColor: [0, 0, 0] }
    });

    let offset = 20;
    // Management Strategy
    series.filter((s) => selected.includes(s.name)).map((s) => {
      const lineHeight = (ms: any) => {
        let height = 4; // for title
        height += 10 + pdf.splitTextToSize(`${ms.text}`, 188).length + 0.5;
        height += ms.links.reduce((prev, current) => prev + pdf.splitTextToSize(`${current.url}`, 188).length + 0.5, 0);
        return height;
      };
      if(remainingHeight < lineHeight(s.management_strategy[0]) + 20) {
        addPageAndHeader(pdf);
        offset = 20;
        pdf.setLineWidth(0.8);
        pdf.line(10, offset - 10, 160, offset - 10);
      } else {
        pdf.setLineWidth(0.8);
        pdf.line(10, offset - 10, 198, offset - 10);
      }
      pdf.setFontSize(14);
      pdf.setFont(undefined, 'bold');
      pdf.text(`${s.name}`, 10, offset);
      pdf.setFontSize(10);
      pdf.setFont(undefined, 'italic');
      pdf.text(`${Math.round(s.data)}t co2e (${Math.round((s.data * 100) / sum)}% off total emissions)`, 10, offset + 5);
      pdf.setFontSize(12);
      pdf.setFont(undefined, 'bold');

      offset += 20;
      remainingHeight -= 40;
      let previous_size = 0;
      s.management_strategy?.map((ms) => {
        if(remainingHeight < lineHeight(ms)) { // todo compare against estimated size
          addPageAndHeader(pdf);
          offset = 30;
        } else {
          offset += previous_size;
        }
        previous_size = 0;
        pdf.setFontSize(12);
        pdf.setFont(undefined, 'bold');
        pdf.setTextColor('black');
        pdf.text(`${ms.title.replaceAll('₂', '2').replaceAll('₄', '4')}`, 10, offset);
        pdf.setFont(undefined, 'normal');
        pdf.setFontSize(10);
        let nbLines = pdf.splitTextToSize(`${ms.text}`, 188).length + 0.5;
        previous_size += 10 + nbLines * 4;
        pdf.text(`${ms.text}`, 10, 5 + offset, { maxWidth: 188 });
        pdf.setTextColor('#00774b');
        let prevNbLineLink = 1;
        ms.links?.map((l) =>{
          pdf.text(`${l.url}`, 10, 5 * prevNbLineLink + nbLines * 4 + offset, { maxWidth: 188 });
          prevNbLineLink += pdf.splitTextToSize(`${l.url}`, 188).length;
        });
        previous_size += prevNbLineLink * 4;
        remainingHeight -= previous_size;
      });
      offset += previous_size + 10;
      pdf.setTextColor('black');
    });
    addFooter(pdf);
    pdf.save(`mla-carbon-calculator-${userDataInput.data.property.name}.pdf`);
    handleClose();
  };

  useEffect(() => {
    const series = getManagementStrategy(categories, result);
    setSeries(groupManagementStrategySeries(series, true));
    setSum(series.reduce((prev, next) => next.data > 0 ? prev + next.data : prev, 0));
  }, [categories, result]);

  function handleSelection(value: string) {
    if(!selected.includes(value))
      setSelected([...selected, value]);
    else
      setSelected(selected.filter((s) => s !== value));
  }

  function handleSelectAll(reverse: boolean) {
    if(reverse)
      setSelected([]);
    else
      setSelected(series.map((s) => s.name));
  }

  return (
    <>
      <DialogTitle>
        <Typography variant={'h4'}>Generate PDF report</Typography>
        <Typography variant={'subtitle1'}>Your report will include the following:</Typography>
      </DialogTitle>
      <DialogContent>
        <ListWrapper disablePadding>
          <ListItem
            sx={{
              color: `${theme.colors.primary.main}`,
              backgroundColor: `${theme.colors.primary.lighter}`
            }}
            button
          >
            <Box>
              <Checkbox checked={true} disabled={true} />
              <b>Result table</b> Scope 1, 2 & 3
            </Box>
          </ListItem>
          <Divider />
          <ListItem
            sx={{
              color: `${theme.colors.primary.main}`,
              backgroundColor: `${theme.colors.primary.lighter}`
            }}
            button
          >
            <Box>
              <Checkbox checked={true} disabled={true} />
              <b>Sequestration</b>
            </Box>
          </ListItem>
          <Divider />
          <ListItem
            sx={{
              color: `${theme.colors.primary.main}`,
              backgroundColor: `${theme.colors.primary.lighter}`
            }}
            button
          >
            <Box>
              <Checkbox checked={true} disabled={true} />
              <b>Emission intensity</b>
            </Box>
          </ListItem>
          <Divider />
        </ListWrapper>
        <Box mt={1}>
          <Typography variant={'subtitle1'}>Please select management strategy to be included:</Typography>
          <ListWrapper disablePadding>
            {series.map((s) => (
                <span key={s.name}>
              <ListItem
                sx={{
                  color: `${theme.colors.primary.main}`,
                  '&:hover': { color: `${theme.colors.primary.dark}` },
                  backgroundColor: `${selected.includes(s.name) ? theme.colors.primary.lighter : null}`
                }}
                button
                onClick={() => handleSelection(s.name)}
              >
                <Box sx={{ width: '80%' }}>
                  <Checkbox checked={selected.includes(s.name)} />
                  {s.name}
                </Box>
                <Box sx={{ width: '20%', textAlign: 'right' }}>
                    <LinearProgressWithLabel
                      value={Math.round((s.data * 100) / sum)}
                    />
                    <ListItemText secondary={`${Math.round(s.data)} t co₂e`} />
                  </Box>
              </ListItem>
              <Divider />
            </span>
              ))}
          </ListWrapper>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color={'secondary'}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant={'outlined'}
          color={'primary'}
          onClick={() => handleSelectAll(selected.length === series.length)}
        >
          { selected.length === series.length ? 'Deselect All' : 'Select All'}
        </Button>
        <Button
          variant="contained"
          onClick={pdf}
        >
          Generate
        </Button>
      </DialogActions>
    </>
  );
}

export default PdfModal;
