import ReactApexChart from "react-apexcharts";
import { ApexOptions } from 'apexcharts';
import { Card, CardContent, CardHeader, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { ResultState } from '../../../../contexts/ResultContext/resultTypes';

export interface BreakdownPerSectionProps {
  result: ResultState;
  categories: string[]
}

function BreakdownPerSection({ result, categories } : BreakdownPerSectionProps) {

  const theme = useTheme();

  const [series, setSeries] = useState([]);
  const [checkedCat, setCheckedCat] = useState(categories.map((c) => { return { id: c, checked: true };}));

  const handleCheckCat = (cat: string) => {
    const newCat = checkedCat.map((c) => {
      if (c.id === cat) return { id: cat, checked: !c.checked }
      else return c
    })
    setCheckedCat(newCat);
  };

  const options = {
    colors: [theme.colors.success.main, '#3BB0C9', theme.colors.warning.main, '#c9713b', '#493bc9'],
    legend: {
      show: true,
      position: 'top'
    },
    chart: {
      height: 450,
      type: 'treemap',
      toolbar: {
        show: false,
      }
    }
  } as ApexOptions;

  useEffect(() => {
    const series = [];
    checkedCat.filter((c) => c.checked).forEach((cat) => {
      const data = [];
      result.scope_1.filter((s) => s.categories.indexOf(cat.id) !== -1 || s.categories.indexOf('all') !== -1).forEach((s) => data.push(
        { x: s.name, y: s.result.filter((r) => r.id === cat.id)?.pop()?.result || 0}
      ));
      result.scope_2.filter((s) => s.categories.indexOf(cat.id) !== -1 || s.categories.indexOf('all') !== -1).forEach((s) => data.push(
        { x: s.name, y: s.result.filter((r) => r.id === cat.id)?.pop()?.result || 0}
      ));
      result.scope_3.filter((s) => s.categories.indexOf(cat.id) !== -1 || s.categories.indexOf('all') !== -1).forEach((s) => data.push(
        { x: s.name, y: s.result.filter((r) => r.id === cat)?.pop()?.result || 0}
      ));
      series.push({
        name: cat.id.charAt(0).toUpperCase() + cat.id.slice(1),
        data: data
      })
    });
    setSeries(series);
  }, [result, checkedCat]);

  return (
    <Card>
      <CardHeader
        title="Breakdown per emission category"
        sx={{ textAlign: 'center' }}
        subheader={
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
          >
            { categories.map((cat) =>
              <FormControlLabel
                key={cat}
                control={
                  <Checkbox
                    // color={['primary', 'info', 'warning'][index] as OverridableStringUnion<'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'default'>}
                    checked={checkedCat.find((c) => c.id === cat).checked}
                    onClick={() => handleCheckCat(cat)}
                  />}
                label={cat.charAt(0).toUpperCase() + cat.slice(1)}
              />
            )}
          </Grid>
        }
      />
      <CardContent sx={{ textAlign: 'center' }} >
        <ReactApexChart options={options} series={series} type="treemap" height={350} />
      </CardContent>
    </Card>
  );
}

export default BreakdownPerSection;
