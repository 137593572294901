import UserInputApi from '../../api/userInput/userInput-api';
import { useAuthenticatedApi } from '../useAuthenticatedApi';
/**
 * Get the userDataInputs list from the api asynchronously
 */
export const useDataInputs = (immediate = true) => {
  const { value, status, abort, execute, error } = useAuthenticatedApi(
    UserInputApi.getUserDataInputList,
    immediate
  );
  return {
    userDataInputs: value ?? [],
    status,
    abort,
    execute,
    error
  };
};
