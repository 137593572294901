import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { RouteParams } from 'src/router';
/**
 * Handle callback details when a user has been redirected to this site
 */
export default function useCallbackHandler() {
  /** TODO: handle the params
   * - Create new
   * - Modify existing
   */
  const { getAccessTokenSilently } = useAuth0();
  const [searchParams] = useSearchParams();
  const { action } = useParams<RouteParams['Integration']>();
  const navigate = useNavigate();
  useEffect(() => {
    // Make sure we fetch the auth to check if auth is current
    getAccessTokenSilently();
    const callback = searchParams.get('callback');
    const state = searchParams.get('state');
    const url = new URL(callback);
    url.searchParams.append('state', state);

    // Set the callback for use later
    const logoUri = searchParams.get('logo');
    localStorage.setItem('ext-callback', url.href);
    if (logoUri !== null) localStorage.setItem('ext-logo', logoUri);

    // Handle actions
    switch (action) {
      case 'manage':
        navigate('/dashboard/calculations?test=abcd', { replace: true });
        break;
      case 'modify':
        const id = searchParams.get('id');
        navigate(`/calculation/${id}`, { replace: true });
        break;
      default:
        history.back();
        break;
    }
  }, [action, getAccessTokenSilently, navigate, searchParams]);
}
