import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { Card, CardContent, CardHeader, Chip } from '@mui/material';
import { useEffect, useState } from 'react';
import { ResultState } from '../../../../contexts/ResultContext/resultTypes';

export interface BreakdownPerGhgProps {
  result: ResultState;
  categories: string[];
}

function BreakdownPerGhg({ result, categories }: BreakdownPerGhgProps) {

  const [series, setSeries] = useState([44, 55, 41]);
  const options = {
    colors: [
      '#DC841A',
      '#6D3076',
      'rgba(75,79,84,0.6)'
    ],
    labels: ['CO₂', 'CH₄', 'N₂O'],
    chart: {
      type: 'donut'
    },
    legend: {
      show: false
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true
            }
          }
        }
      }
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      style: {
        colors: ['#fff']
      },
      formatter: function (val, opt) {
        switch (opt.seriesIndex) {
          case 0:
            return `CO₂: ${Math.round(parseFloat(val.toString()))}%`;
          case 1:
            return `CH₄: ${Math.round(parseFloat(val.toString()))}%`;
          case 2:
            return `N₂O: ${Math.round(parseFloat(val.toString()))}%`;
        }
      },
      offsetX: 0,
      dropShadow: {
        enabled: true
      }
    }
  } as ApexOptions;

  useEffect(() => {
    let co2 = 0;
    let n2o = 0;
    let ch4 = 0;
    categories.forEach((cat) => {
      result.scope_1
        .filter(
          (s) =>
            s.categories.indexOf(cat) !== -1 ||
            s.categories.indexOf('all') !== -1
        )
        .forEach((s) => {
          if (s.id.indexOf('co2') !== -1) {
            co2 += s.result.filter((r) => r.id === cat)?.pop()?.result || 0;
          }
          if (s.id.indexOf('n2o') !== -1) {
            n2o += s.result.filter((r) => r.id === cat)?.pop()?.result || 0;
          }
          if (s.id.indexOf('ch4') !== -1) {
            ch4 += s.result.filter((r) => r.id === cat)?.pop()?.result || 0;
          }
        });
    });
    setSeries([Math.round(co2), Math.round(ch4), Math.round(n2o)]);
  }, [categories, result]);

  return (
    <Card>
      <CardHeader
        title="Scope 1 Emissions per GHG"
        sx={{ textAlign: 'center' }}
        subheader={
          <>
            <p>GHG values are in co₂ equivalent.</p>
            <Chip sx={{ ml: 1, backgroundColor: '#DC841A', color: 'white' }} label={`CO₂: ${series[0]} t`} />
            <Chip sx={{ ml: 1, backgroundColor: '#6D3076', color: 'white' }} label={`CH₄: ${series[1]} t`} />
            <Chip sx={{ ml: 1, backgroundColor: 'rgba(75,79,84,0.6)', color: 'white' }} label={`N₂O: ${series[2]} t`} />
          </>
        }
      />
      <CardContent sx={{ textAlign: 'center' }}>
        <ReactApexChart
          options={options}
          series={series}
          height={350}
          type="donut"
        />
      </CardContent>
    </Card>
  );
}

export default BreakdownPerGhg;
