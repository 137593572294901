import {
  ResultAction,
  ResultActionE,
  ResultState,
  ScopeItem
} from './resultTypes';
import produce from 'immer';

export const defaultResultState: ResultState = {
  scope_1: [],
  scope_2: [],
  scope_3: [],
  sequestration: [],
  sale_inventory: [],
  saved: false
};

export const resultReducer = produce(
  (draft: ResultState, action: ResultAction) => {
    switch (action.type) {
      case ResultActionE.UpdateResult:
        // eslint-disable-next-line no-case-declarations
        const scope = draft[
          action.payload.scope as keyof ResultState
        ] as ScopeItem[];
        // eslint-disable-next-line no-case-declarations
        const scopeItem = scope?.find((s) => s.id === action.payload.id);
        if (scopeItem) {
          scopeItem.status = action.payload.status;
          scopeItem.result = action.payload.result;
        }
        break;
      case ResultActionE.UpdateFullResult:
        if (action.payload.newResult) {
          draft.scope_1 = action.payload.newResult.scope_1;
          draft.scope_2 = action.payload.newResult.scope_2;
          draft.scope_3 = action.payload.newResult.scope_3;
          draft.sequestration = action.payload.newResult.sequestration;
          draft.sale_inventory = action.payload.newResult.sale_inventory;
          draft.saved = action.payload.newResult.saved;
          draft._id = action.payload.newResult._id;
          draft.userDataInput = action.payload.newResult.userDataInput;
          draft.methodology = action.payload.newResult.methodology;
          draft.methodologyVersion =
            action.payload.newResult.methodologyVersion;
          draft.uid = action.payload.newResult.uid;
          draft.date = action.payload.newResult.date;
          draft.total = action.payload.newResult.total;
          draft.beefTotal = action.payload.newResult.beefTotal;
          draft.sheepTotal = action.payload.newResult.sheepTotal;
          draft.cropTotal = action.payload.newResult.cropTotal;
        }
        break;
      case ResultActionE.SetSaveResult:
        draft.saved = true;
        draft._id = action.payload.newId;
        break;
      case ResultActionE.Reset:
        break;
      default:
        break;
      // do something;
    }
  }
);
