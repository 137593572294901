import CarbonCalculatorApi from '../carbon-calculator-api';
import { ResultState } from '../../contexts/ResultContext/resultTypes';
import { ResultStore } from 'src/services/LocalDB';

/**
 * Handles all requests to the `/run` resource.
 */
class ResultApi {
  private resource = 'result';

  /**
   * Get a Result by id
   */
  getResult = async (
    userDataInputId: string,
    token: string,
    controller?: AbortController
  ) => {
    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`
      };
      const response = await CarbonCalculatorApi.get(
        `/${this.resource}/udi/${userDataInputId}`,
        {
          params: {},
          signal: controller?.signal,
          headers
        }
      );
      return response.data as ResultState;
    } else {
      const result = await ResultStore.getByInputDataId(userDataInputId);
      return result;
    }
  };

  /**
   * Create Result
   */
  createResult = async (
    result: ResultState,
    token: string,
    controller?: AbortController
  ) => {
    const r = { ...result };
    r.saved = true;
    delete r._id;
    delete r.uid;
    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`
      };
      const response = await CarbonCalculatorApi.post(
        `/${this.resource}`,
        { ...r },
        {
          signal: controller?.signal,
          headers
        }
      );
      return response.data.id as string;
    } else {
      await ResultStore.add(result);
    }
  };

  /**
   * Update Result
   */
  updateResult = async (
    result: ResultState,
    token: string,
    controller?: AbortController
  ) => {
    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`
      };
      const r = { ...result };
      r.saved = true;
      delete r._id;
      delete r.uid;
      const response = await CarbonCalculatorApi.put(
        `/${this.resource}/${result._id}`,
        { ...r },
        {
          signal: controller?.signal,
          headers
        }
      );
      return response.data as boolean;
    } else {
      ResultStore.put(result);
    }
  };

  /**
   * Delete Result
   */
  deleteResult = async (
    resultId: string,
    token: string,
    controller?: AbortController
  ) => {
    const headers = {
      Authorization: `Bearer ${token}`
    };
    const response = await CarbonCalculatorApi.delete(
      `/${this.resource}/${resultId}`,
      {
        params: {},
        signal: controller?.signal,
        headers
      }
    );
    return response.data as boolean;
  };
}

export default new ResultApi();
