import { CalculationAction, CalculationActionE, CalculationState } from './calculationTypes';
import produce from 'immer';

export const defaultCalculationState: CalculationState = {
  id: '',
  name: '',
  formulas: []
};

export const calculationReducer = produce((draft: CalculationState, action: CalculationAction) => {
  switch (action.type) {
    case CalculationActionE.UpdateFormula:
      // eslint-disable-next-line no-case-declarations
      const formulaItem = draft.formulas.find((f) => f.id === action.payload.item.id);
      if (formulaItem) {
        formulaItem.status = action.payload.item.status;
      }
      break;
    case CalculationActionE.UpdateFullCalculation:
      if(action.payload.newCalculation) {
        draft.id = action.payload.newCalculation.id;
        draft.name = action.payload.newCalculation.name;
        draft.formulas = action.payload.newCalculation.formulas;
      }
      break;
    case CalculationActionE.Reset:
      break;
    default:
      break;
    // do something;
  }
});
