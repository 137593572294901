import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useAsync } from '../useAsync';
import MethodologyApi from '../../api/methodology/methodology-api';
import { useAuth0 } from '@auth0/auth0-react';


/**
 * Get the methodologies from the api asynchronously
 */
export const useMethodologies = (immediate = true) => {
  const { getAccessTokenSilently } = useAuth0();
  const [controller] = useState(new AbortController());

  const load = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();
    return MethodologyApi.getMethodologiesList(accessToken);
  }, [getAccessTokenSilently]);
  const { status, value, error, execute } = useAsync(load, immediate);

  // Abort the call if unmounting
  useEffect(() => () => controller.abort(), [controller]);
  return {
    methodologies: value ?? [],
    status,
    execute: execute,
    abort: () => controller.abort(),
    error: error as AxiosError,
  };
};
