import { openDB, DBSchema } from 'idb';
import { ResultState } from 'src/contexts/ResultContext/resultTypes';
import { UserDataInputState } from 'src/contexts/UserDataInputContext/userDataInputTypes';
import { generateLocalId } from 'src/utils';

interface LocalDB extends DBSchema {
  UserInput: {
    key: string;
    value: UserDataInputState;
  };
  Result: {
    key: string;
    value: ResultState;
    indexes: { inputIdIndex: string };
  };
}

interface IDBStore<T, I> {
  add: (data: T) => Promise<string>;
  put: (data: T) => Promise<string>;
  get: (id: I) => Promise<T>;
  del: (id: I) => Promise<void>;
  count: () => Promise<number>;
  getAll: () => Promise<T[]>;
}

// export const guestDB = openDB<LocalDB>('GuestDB');
export const guestDB = openDB<LocalDB>('GuestDB', 1, {
  upgrade(db) {
    db.createObjectStore('UserInput', { keyPath: '_id' });
    const resultStore = db.createObjectStore('Result', { keyPath: '_id' });
    resultStore.createIndex('inputIdIndex', 'userDataInput');
  }
});

const UserInputStore: IDBStore<UserDataInputState, string> = {
  async add(userInput) {
    if (!userInput._id) {
      // Mimic the remote database id generate on create
      userInput._id = generateLocalId();
    }
    return (await guestDB).add('UserInput', userInput);
  },
  async put(userInput) {
    if (!userInput._id) {
      // Mimic the remote database id generate on create
      userInput._id = generateLocalId();
    }
    return (await guestDB).put('UserInput', userInput);
  },
  async get(id) {
    return (await guestDB).get('UserInput', id);
  },
  async del(id) {
    return (await guestDB).delete('UserInput', id);
  },
  async count() {
    return (await guestDB).count('UserInput');
  },
  async getAll() {
    return (await guestDB).getAll('UserInput');
  }
};

const ResultStore: IDBStore<ResultState, string> & {
  /**
   * Fetch the most recently calculated result based on a user data input id
   * @param dataId The id of the user data input used to compute these results
   * @returns The most recent result
   */
  getByInputDataId: (dataId: string) => Promise<ResultState | undefined>;
  delByInputDataId: (dataId: string) => void;
} = {
  async add(results) {
    results._id = generateLocalId();
    return (await guestDB).add('Result', results);
  },
  async put(results) {
    if (!results._id) {
      results._id = generateLocalId();
    }
    return (await guestDB).put('Result', results);
  },
  async get(id) {
    return (await guestDB).get('Result', id);
  },
  async getByInputDataId(dataId) {
    const index = (await guestDB)
      .transaction('Result')
      .store.index('inputIdIndex');
    const resultsByDate = (await index.getAll(dataId)).sort(
      (a, b) => a.date.getTime() - b.date.getTime()
    );
    // get the most recent
    return resultsByDate[resultsByDate.length - 1];
  },
  async delByInputDataId(dataId) {
    const index = (await guestDB)
      .transaction('Result')
      .store.index('inputIdIndex');
    const indexes = await index.getAll(dataId);
    indexes.forEach(async (index) => (await guestDB).delete('Result', index._id));
  },
  async del(id) {
    return (await guestDB).delete('Result', id);
  },
  async count() {
    return (await guestDB).count('Result');
  },
  async getAll() {
    return (await guestDB).getAll('Result');
  }
};

export { UserInputStore, ResultStore };
