import {
  Box,
  Container,
  styled,
  Typography,
  LinearProgress
} from '@mui/material';
import Footer from 'src/components/Footer';
const LoadingWrapper = styled(Box)(
  () => `
    margin-top: 5em;
    overflow: auto;
    flex: 1;
    overflow-x: hidden;
    align-items: center;
`
);
const TypographyH1 = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(50)};
`
);
const MLAAvatar = styled(Box)(
  ({ theme }) => `
    width: ${theme.spacing(50)};
    // border-radius: ${theme.general.borderRadius};
    // flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto ${theme.spacing(2)};

    img {
      width: 50%;
      display: block;
    }
`
);

const Loading = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1
      }}
    >
      <LoadingWrapper>
        <Container maxWidth="lg">
          <MLAAvatar>
            <img
              src="/static/images/logo/mla-logo-landing.svg"
              alt="Meat and Livestock Australia logo"
            />
          </MLAAvatar>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <TypographyH1 sx={{ mb: 2 }} variant="h1">
              MLA Carbon Calculator
            </TypographyH1>

            <Typography>Loading...</Typography>
            <Box sx={{ width: '80%' }}>
              <LinearProgress />
            </Box>
          </Box>
        </Container>
        <Footer />
      </LoadingWrapper>
    </Box>
  );
};

export default Loading;
