import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import TagManager from 'react-gtm-module';
import MyMlaProvider from './services/Auth/auth0';
import ThemeProvider from './theme/ThemeProvider';
import { CssBaseline } from '@mui/material';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_TAG
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <HelmetProvider>
    <SidebarProvider>
      <ThemeProvider>
        <BrowserRouter>
          <MyMlaProvider>
            <CssBaseline />
            <App />
          </MyMlaProvider>
        </BrowserRouter>
      </ThemeProvider>
    </SidebarProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
