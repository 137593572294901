import { Box, Button, List } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { NavLink, useLocation } from 'react-router-dom';

function HeaderMenu() {
  const { pathname } = useLocation();

  return (
    <>
      {!pathname.includes('dashboard') && !pathname.includes('callback') && (
        <List disablePadding component={Box} display="flex">
          <Button
            startIcon={<ArrowBackIcon />}
            color="secondary"
            component={NavLink}
            to="/dashboard"
          >
            Back to dashboard
          </Button>
        </List>
      )}
    </>
  );
}

export default HeaderMenu;
