import { createContext, useCallback, useReducer } from 'react';

import {
  defaultMethodologyState,
  methodologyReducer,
} from './methodologyReducers';

import {
  MethodologyActionE, MethodologyContextI
} from './methodologyTypes';

import { Methodology } from '../../api/carbon-calculator-api-types';

// react 18 as no more children in default React.Component
interface MethodologyContextProps {
  children: React.ReactNode;
}

const defaultFunction = () => {
  throw new Error('Function not implemented');
};

const defaultMethodologyContext: MethodologyContextI = {
  AddFormula: defaultFunction,
  UpdateMethodology: defaultFunction,
  UpdateFormula: defaultFunction,
  UpdateOutput: defaultFunction,
  UpdateBackgroundData: defaultFunction,
  ...defaultMethodologyState,
};
const MethodologyContext = createContext<MethodologyContextI>(defaultMethodologyContext);

export const MethodologyContextProvider: React.FC<MethodologyContextProps> = ({ children }) => {
  const [state, dispatch] = useReducer(methodologyReducer, defaultMethodologyState);

  const AddFormula = useCallback((sectionId: string, newFormula: any) => {
    dispatch({
      type: MethodologyActionE.AddFormula,
      payload: { sectionId, newFormula },
    });
  }, []);

  const UpdateMethodology = useCallback((newMethodology: Methodology) => {
    dispatch({
      type: MethodologyActionE.UpdateMethodology,
      payload: { newMethodology },
    });
  }, []);

  const UpdateFormula = useCallback((sectionId: string, formulaId: string, newFormula: any) => {
    dispatch({
      type: MethodologyActionE.UpdateFormula,
      payload: { sectionId, formulaId, newFormula },
    });
  }, []);

  const UpdateOutput = useCallback((sectionId: string, outputId: string, newOutput: any) => {
    dispatch({
      type: MethodologyActionE.UpdateOutput,
      payload: { sectionId, outputId, newOutput },
    });
  }, []);

  const UpdateBackgroundData = useCallback((backgroundDataId: string, newBackgroundData: any) => {
    dispatch({
      type: MethodologyActionE.UpdateBackgroundData,
      payload: { backgroundDataId, newBackgroundData },
    });
  }, []);

  return (
    <MethodologyContext.Provider
      value={{
        AddFormula,
        UpdateMethodology,
        UpdateFormula,
        UpdateOutput,
        UpdateBackgroundData,
        ...state,
      }}
    >
      {children}
    </MethodologyContext.Provider>
  );
};

export default MethodologyContext;
