import { Box, Container, Typography, styled, Button } from '@mui/material';

const FooterWrapper = styled(Container)(
  ({ theme }) => `
    margin-top: ${theme.spacing(4)};
  `);

function Footer() {
  return (
    <FooterWrapper className="footer-wrapper">
      <Box
        pb={4}
        display={{ xs: 'block', md: 'flex' }}
        alignItems="center"
        textAlign={{ xs: 'center', md: 'left' }}
        justifyContent="flex-end"
      >
        <Box>
          <Typography variant="subtitle1">
            <Button
              href='https://www.mla.com.au/general/privacy/'
              target={'_blank'}
              rel="noreferrer"
              variant={'text'}
              size={'small'}
              color={'inherit'}
            >
              MLA privacy policy
            </Button>
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle1">
            &nbsp;&copy; 2023 Meat & Livestock Australia Limited ABN 39 081 678 364
          </Typography>
        </Box>
      </Box>
    </FooterWrapper>
  );
}

export default Footer;
