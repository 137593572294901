import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Link,
  ListItem,
  ListItemText,
  styled,
  Typography
} from '@mui/material';
import LinearProgress, {
  LinearProgressProps
} from '@mui/material/LinearProgress';
import { useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ResultState } from '../../../../contexts/ResultContext/resultTypes';
import ListWrapper from '../../../../components/ListWrapper/ListWrapper';

const ListItemLogo = styled('img')(
  ({ theme }) => `
      border: 1px solid ${theme.colors.alpha.black[30]};
      border-radius: ${theme.general.borderRadius};
      padding: ${theme.spacing(1)};
      margin-right: ${theme.spacing(2)};
      background: ${theme.colors.alpha.white[100]};
`
);

export interface ManagementStrategyProps {
  result: ResultState;
  categories: string[];
}

export const getManagementStrategy = (categories: string[], result: ResultState) => {
  const series = [];
  categories.forEach((cat) => {
    result.scope_1
      .filter(
        (s) =>
          s.categories.indexOf(cat) !== -1 ||
          s.categories.indexOf('all') !== -1
      )
      .forEach((s) =>
        series.push({
          name: s.name
            .replace('CO₂ - ', '')
            .replace('CH₄ - ', '')
            .replace('N₂O - ', ''),
          data: s.result.filter((r) => r.id === cat)?.pop()?.result || 0,
          svg: s.svg,
          management_strategy: s.management_strategy
        })
      );
    result.scope_2
      .filter(
        (s) =>
          s.categories.indexOf(cat) !== -1 ||
          s.categories.indexOf('all') !== -1
      )
      .forEach((s) =>
        series.push({
          name: s.name
            .replace('CO₂ - ', '')
            .replace('CH₄ - ', '')
            .replace('N₂O - ', ''),
          data: s.result.filter((r) => r.id === cat)?.pop()?.result || 0,
          svg: s.svg,
          management_strategy: s.management_strategy
        })
      );
    result.scope_3
      .filter(
        (s) =>
          s.categories.indexOf(cat) !== -1 ||
          s.categories.indexOf('all') !== -1
      )
      .forEach((s) =>
        series.push({
          name: s.name
            .replace('CO₂ - ', '')
            .replace('CH₄ - ', '')
            .replace('N₂O - ', ''),
          data: s.result.filter((r) => r.id === cat)?.pop()?.result || 0,
          svg: s.svg,
          management_strategy: s.management_strategy
        })
      );
    result.sequestration
      .filter(
        (s) =>
          s.categories.indexOf(cat) !== -1 ||
          s.categories.indexOf('all') !== -1
      )
      .forEach((s) =>
        series.push({
          name: s.name
            .replace('CO₂ - ', '')
            .replace('CH₄ - ', '')
            .replace('N₂O - ', ''),
          data: s.result.filter((r) => r.id === cat)?.pop()?.result || 0,
          svg: s.svg,
          management_strategy: s.management_strategy
        })
      );
  });
  return series;
}

export const groupManagementStrategySeries = (series, withManagementStrategy: boolean) => {
  // grouping scope together to avoid duplicate
  return Array.from(
    series.reduce(
      (prev, { name, data, svg, management_strategy }) =>
        prev.set(name, {
          data: (prev.get(name)?.data || 0) + data,
          svg: svg,
          management_strategy: management_strategy
        }),
      new Map()
    ),
    ([name, { data, svg, management_strategy }]) => ({
      name,
      data,
      svg,
      management_strategy
    })
  ).filter((s) => withManagementStrategy ? s.management_strategy.length : true).sort((a, b) => b.data - a.data);
}

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
      <Box sx={{ width: '100%', ml: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
    </Box>
  );
}

function ManagementStrategy({ result, categories }: ManagementStrategyProps) {
  const theme = useTheme();

  const [series, setSeries] = useState([]);
  const [sum, setSum] = useState(0);

  useEffect(() => {
    const series = getManagementStrategy(categories, result);
    setSeries(groupManagementStrategySeries(series, true));
    setSum(series.reduce((prev, next) => next.data > 0 ? prev + next.data : prev, 0));
  }, [categories, result]);

  return (
    <Card>
      <CardHeader
        title="Management Strategy"
        subheader={<p>Strategies to reduce your emissions</p>}
      />
      <Divider />
      <ListWrapper disablePadding>
        {series.map((s) => (
          <span key={s.name}>
            <ListItem
              sx={{
                color: `${theme.colors.primary.main}`,
                '&:hover': { color: `${theme.colors.primary.dark}` }
              }}
            >
              <Accordion sx={{ width: '100%' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                  <Box sx={{ width: '15%' }}>
                    <ListItemLogo src={s.svg} alt={s.name} />
                  </Box>
                  <Box sx={{ width: '65%' }}>
                    <b>{s.name}</b>
                    <ul>
                      {s.management_strategy?.map((ms, index) => (
                        <li key={index}>
                          {ms.title}
                        </li>
                      ))}
                    </ul>
                    <Button
                      sx={{ mt: 1 }}
                      size={'small'}
                      variant={'text'}
                    >
                      Click to learn more
                    </Button>
                  </Box>
                  <Box sx={{ width: '20%', textAlign: 'right' }}>
                    <LinearProgressWithLabel
                      value={Math.round((s.data * 100) / sum)}
                    />
                    <ListItemText secondary={`${Math.round(s.data)} t co₂e`} />
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  {s.management_strategy?.map((ms, index) => (
                    <Box pt={2} key={index}>
                      <Divider />
                      <Typography variant={'h4'} py={2}>{ms.title}</Typography>
                      <Typography py={2}>{ms.text.split('\n').map(c => <p key={c}> {c} </p>)}</Typography>
                      <ul>
                        {ms.links?.map((l) => (
                          <li key={l.url}>
                            <Link
                              href={l.url}
                              variant="body2"
                              underline="hover"
                              target="_blank"
                            >
                              {l.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            </ListItem>
            <Divider />
          </span>
        ))}
        <span>
            <ListItem
              sx={{
                color: `${theme.colors.primary.main}`,
                '&:hover': { color: `${theme.colors.primary.dark}` }
              }}
            >
             <Accordion sx={{ width: '100%' }}>
              <AccordionSummary>
                <Box sx={{ width: '15%' }}>
                  <ListItemLogo src="/static/images/scopeLogo/additional_links.svg" alt="Additional support tools" />
                </Box>
                <Box sx={{ width: '65%' }}>
                  <b>Additional support tools</b>
                  <ul>
                    <li>
                      <Link
                        href="https://tools.mla.com.au/cop/"
                        variant="body2"
                        underline="hover"
                        target="_blank"
                      >
                        Cost of production beef, lamb & goat
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="https://piccc.org.au/resources/Tools.html"
                        variant="body2"
                        underline="hover"
                        target="_blank"
                      >
                        Greenhouse Accounting Frameworks (GAF) for Australian Primary Industries
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="https://fertilizer.org.au/fertcare"
                        variant="body2"
                        underline="hover"
                        target="_blank"
                      >
                        FertCare Australia
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="https://www.mla.com.au/globalassets/mla-corporate/research-and-development/program-areas/livestock-production/211207-carbon-neutral-red-meat-product-catalogue.pdf"
                        variant="body2"
                        underline="hover"
                        target="_blank"
                      >
                        CN30/MLA
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="https://elearning.mla.com.au/"
                        variant="body2"
                        underline="hover"
                        target="_blank"
                      >
                        The toolbox- MLA
                      </Link>
                    </li>
                  </ul>
                </Box>
              </AccordionSummary>
             </Accordion>
            </ListItem>
            <Divider />
          </span>
      </ListWrapper>
    </Card>
  );
}

export default ManagementStrategy;
