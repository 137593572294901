import {
  alpha,
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Skeleton,
  styled
} from '@mui/material';
import { precisionOrBlank } from '../../../../utils';
import { ResultState } from '../../../../contexts/ResultContext/resultTypes';

export interface ScopeTableProps {
  result: ResultState;
  categories: string[];
  enterpriseTotal: string;
  isLoaded: boolean;
}

const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
    margin: 0 auto;
    margin-bottom: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${theme.spacing(0.5)};
    border-radius: 60px;
    height: ${theme.spacing(9.5)};
    width: ${theme.spacing(9.5)};
    background: ${
      theme.palette.mode === 'dark'
        ? theme.colors.alpha.trueWhite[30]
        : alpha(theme.colors.alpha.black[100], 0.07)
    };
  
    img {
      background: ${theme.colors.alpha.trueWhite[100]};
      padding: ${theme.spacing(0.5)};
      display: block;
      border-radius: inherit;
      height: ${theme.spacing(7.5)};
      width: ${theme.spacing(7.5)};
    }
`
);

export const getTotalPerCategory = (result: ResultState, cat: string, sequestration: boolean) => {
  return precisionOrBlank(
    result?.scope_1.reduce(
      (prev: number, current: any) =>
        prev +
        (current.result?.find((r: any) => r.id === cat)
          ?.result || 0),
      0
    ) +
    result?.scope_2.reduce(
      (prev: number, current: any) =>
        prev +
        (current.result?.find((r: any) => r.id === cat)
          ?.result || 0),
      0
    ) +
    result?.scope_3.reduce(
      (prev: number, current: any) =>
        prev +
        (current.result?.find((r: any) => r.id === cat)
          ?.result || 0),
      0
    ) +
    (sequestration ? result?.sequestration.reduce(
      (prev: number, current: any) =>
        prev +
        (current.result?.find((r: any) => r.id === cat)
          ?.result || 0),
      0
    ) : 0)
  )
}

function SummaryTable({
  result,
  categories,
  enterpriseTotal,
  isLoaded
}: ScopeTableProps) {

  const categoryLogo = (cat: string) => {
    switch (cat) {
      case 'beef':
        return 'cattle-tco2.svg';
      case 'feedlot':
        return 'feedlot-tco2.svg';
      case 'sheep':
        return 'sheep-tco2.svg';
      case 'goat':
        return 'goat-tco2.svg';
      case 'crop':
        return 'cropping-green.svg';
    }
  };

  return (
    <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
      {categories.map((cat: string) => (
        <Grid key={cat} xs={12} sm={6} md={3} xl={12 / (categories.length + 1)} item>
          <Card
            sx={{
              px: 1
            }}
          >
            <CardContent sx={{ textAlign: 'center' }}>
              <AvatarWrapper>
                <img
                  alt={cat}
                  src={`/static/images/avatars/${categoryLogo(cat)}`}
                  style={{ overflow: 'visible' }}
                />
              </AvatarWrapper>
              <Typography variant="h6" noWrap>
                {cat.charAt(0).toUpperCase() + cat.slice(1)}
              </Typography>
              <Typography variant="subtitle1" noWrap></Typography>
              <Box sx={{ pt: 3 }}>
                <Typography variant="h4" gutterBottom noWrap>
                  {isLoaded ? (
                    getTotalPerCategory(result, cat, true) + ' t'
                  ) : (
                    <Skeleton variant="text" animation={false} />
                  )}
                </Typography>
                <Typography variant="subtitle2" noWrap>
                  {isLoaded ? (
                    getTotalPerCategory(result, cat, false) + ' t (excl sq)'
                  ) : (
                    <Skeleton variant="text" animation={false} />
                  )}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
      {categories.length > 1 && (
        <Grid xs={12} sm={6} md={3} xl={12 / (categories.length + 1)} item>
          <Card
            sx={(theme) => ({
              px: 1,
              backgroundColor: theme.colors.success.light
            })}
          >
            <CardContent sx={{ textAlign: 'center' }}>
              <AvatarWrapper>
                <img
                  alt="Total"
                  src="/static/images/avatars/mapshomeworkicon.svg"
                />
              </AvatarWrapper>
              <Typography variant="h6" noWrap>
                <b>Total</b>
              </Typography>
              <Typography variant="subtitle1" noWrap></Typography>
              <Box sx={{ pt: 3 }}>
                <Typography variant="h4" gutterBottom noWrap>
                  ={' '}
                  {isLoaded ? (
                    enterpriseTotal
                  ) : (
                    <Skeleton variant="text" animation={false} />
                  )}
                </Typography>
                <Typography variant="subtitle2" noWrap>
                  t co₂e / year
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
}

export default SummaryTable;
