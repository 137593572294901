export interface CalculationContextI {
  calculation: CalculationState;
  UpdateFormula: (item: FormulaItem) => void;
  UpdateFullCalculation: (newCalculation: CalculationState) => void;
}

/**
 *
 *
 * CALCULATION TYPES
 *
 *
 */
export interface CalculationState {
  id: string,
  name: string,
  formulas: FormulaItem[]
}

export type FormulaItem = {
  id: string,
  status: string
}

export enum CalculationActionE {
  UpdateFormula = 'UpdateFormula',
  UpdateFullCalculation = 'UpdateFullCalculation',
  Reset = 'Reset',
}

export type CalculationAction =
  | {
      type: CalculationActionE.UpdateFormula;
      payload: { item: FormulaItem };
  }
  | {
      type: CalculationActionE.UpdateFullCalculation;
      payload: { newCalculation: CalculationState };
    }
  | { type: CalculationActionE.Reset }; // reset to default
