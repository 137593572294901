import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState
} from 'react';
import { isEmptyObject } from 'src/utils';

import { defaultInputDataState, inputReducer } from './userDataInputReducers';
import UserInputApi from '../../api/userInput/userInput-api';

import {
  InputContextI,
  GroupInput,
  GroupData,
  InputActionE,
  ProportionData,
  EnergyData,
  OffFarmData,
  PropertyData,
  VegetationData,
  SavannaBurningData,
  CropGroup,
  FeedlotTransportInputs,
  OtherData
} from './userDataInputTypes';
import { useAuth0 } from '@auth0/auth0-react';

// react 18 as no more children in default React.Component
interface UserDataInputContextProps {
  children: React.ReactNode;
}

const defaultFunction = () => {
  throw new Error('Function not implemented');
};

const defaultUserDataInputContext: InputContextI = {
  updateFullUserDataInput: defaultFunction,
  saveVegetation: defaultFunction,
  deleteVegetation: defaultFunction,
  saveCrop: defaultFunction,
  deleteCrop: defaultFunction,
  UpdateUserDataInput: defaultFunction,
  addGroupInput: defaultFunction,
  deleteGroupInput: defaultFunction,
  getGroupInput: defaultFunction,
  saveProportion: defaultFunction,
  getProportion: defaultFunction,
  saveProperty: defaultFunction,
  saveYear: defaultFunction,
  saveSavannaBurning: defaultFunction,
  saveInputMode: defaultFunction,
  userDataInput: defaultInputDataState,
  saveFeedlotTransport: defaultFunction,
  saveOtherData: defaultFunction
};
const UserDataInputContext = createContext<InputContextI>(
  defaultUserDataInputContext
);

const useSaveOnChange: <T extends Object>(
  saveFunction: (data: Object, token: string) => any,
  watchItem: T
) => void = (saveFunction, watchItem) => {
  const prevItem = useRef(watchItem);
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<null | Error>(null); // todo error management

  useEffect(() => {
    if (
      !isEmptyObject(watchItem) &&
      !isEmptyObject(prevItem.current) &&
      JSON.stringify(watchItem) !== JSON.stringify(prevItem.current)
    ) {
      // Only save if the item has changed
      (async () => {
        let accessToken: string;
        if (isAuthenticated) {
          accessToken = await getAccessTokenSilently();
        }

        // Save to api
        try {
          await saveFunction(watchItem, accessToken);
        } catch (e) {
          console.warn(e);
          setError(e);
        }
      })();
    }
    prevItem.current = watchItem;
  }, [getAccessTokenSilently, saveFunction, watchItem, isAuthenticated]);
};

export const UserDataInputContextProvider = ({
  children
}: UserDataInputContextProps) => {
  const [state, dispatch] = useReducer(inputReducer, defaultInputDataState);

  /**
   *
   *
   * Group Inputs
   *
   *
   */

  const UpdateUserDataInput = useCallback((userDataInput: any) => {
    dispatch({
      type: InputActionE.UpdateUserDataInput,
      payload: { userDataInput }
    });
  }, []);
  const updateFullUserDataInput = useCallback((newUserDataInput: any) => {
    dispatch({
      type: InputActionE.UpdateFullUserDataInput,
      payload: { newUserDataInput }
    });
  }, []);
  const addGroupInput = useCallback(
    (targetGroup: keyof GroupData, groupData: GroupInput) => {
      dispatch({
        type: InputActionE.AddGroup,
        payload: { targetGroup, groupData }
      });
    },
    []
  );
  const deleteGroupInput = useCallback(
    (targetGroup: keyof GroupData, groupData: GroupInput) => {
      dispatch({
        type: InputActionE.DeleteGroup,
        payload: { targetGroup, groupData }
      });
    },
    []
  );
  const getGroupInput = useCallback(
    (targetGroup: keyof GroupData, groupType: string) =>
      state.data[targetGroup].find((group: any) => group.type === groupType),
    [state.data]
  );

  /**
   *
   *
   * Proportion Inputs
   *
   *
   */

  const saveProportion = useCallback(
    (key: keyof OffFarmData | keyof EnergyData, data: ProportionData) => {
      dispatch({
        type: InputActionE.SaveProportion,
        payload: { resourceKey: key, data }
      });
    },
    []
  );
  const getProportion = useCallback(
    (key: keyof OffFarmData | keyof EnergyData) => {
      return state.data[key];
    },
    [state.data]
  );

  /**
   *
   *
   * Property Inputs
   *
   *
   */

  const saveProperty = useCallback((propertyData: Partial<PropertyData>) => {
    dispatch({ type: InputActionE.SaveProperty, payload: propertyData });
  }, []);

  const saveInputMode = useCallback(
    (mode: 'seasonally' | 'monthly', animal: 'sheep' | 'beef') => {
      dispatch({
        type: InputActionE.ToggleInputMode,
        payload: { mode, animal }
      });
    },
    []
  );

  /**
   *
   *
   * Vegetation Input
   *
   *
   */

  const saveVegetation = useCallback(
    (vegetationData: VegetationData, index?: number) => {
      dispatch({
        type: InputActionE.SaveVegetation,
        payload: { data: vegetationData, index }
      });
    },
    []
  );
  const deleteVegetation = useCallback((index: number) => {
    dispatch({
      type: InputActionE.DeleteVegetation,
      payload: { index }
    });
  }, []);

  /**
   *
   *
   * Crop Input
   *
   *
   */

  const saveCrop = useCallback((cropGroup: CropGroup, index?: number) => {
    dispatch({
      type: InputActionE.SaveCrop,
      payload: { data: cropGroup, index }
    });
  }, []);
  const deleteCrop = useCallback((index: number) => {
    dispatch({
      type: InputActionE.DeleteCrop,
      payload: { index }
    });
  }, []);

  /**
   *
   *
   * Savanna Burning Input
   *
   *
   */

  const saveSavannaBurning = useCallback(
    (savannaBurningData: SavannaBurningData) => {
      dispatch({
        type: InputActionE.SaveSavannaBurning,
        payload: savannaBurningData
      });
    },
    []
  );

  /**
   *
   *
   * Static fields
   *
   *
   */

  const saveYear = useCallback((year: string) => {
    dispatch({
      type: InputActionE.SaveStaticFieldString,
      payload: { field: 'year', data: year }
    });
  }, []);

  const saveFeedlotTransport = useCallback((data: FeedlotTransportInputs) => {
    dispatch({
      type: InputActionE.SaveFeedlotTransport,
      payload: data
    });
  }, []);

  const saveOtherData = useCallback((data: OtherData) => {
    dispatch({
      type: InputActionE.SaveOtherData,
      payload: data
    });
  }, []);

  useSaveOnChange(
    UserInputApi.saveUserDataInput,
    state.date === '1999-01-01T12:12:12.122Z' ? {} : state
  );

  return (
    <UserDataInputContext.Provider
      value={{
        UpdateUserDataInput,
        updateFullUserDataInput: updateFullUserDataInput,
        addGroupInput,
        deleteGroupInput,
        getGroupInput,
        saveProportion,
        getProportion,
        saveProperty,
        saveYear,
        saveVegetation,
        deleteVegetation,
        saveCrop,
        deleteCrop,
        saveSavannaBurning,
        saveInputMode,
        userDataInput: state,
        saveFeedlotTransport,
        saveOtherData
      }}
    >
      {children}
    </UserDataInputContext.Provider>
  );
};

export const usePropertyData = () => {
  const { userDataInput } = useContext(UserDataInputContext);
  if (!userDataInput || isEmptyObject(userDataInput)) {
    throw new Error('No active context for user inputs');
  }
  return userDataInput.data?.property;
};

export const useFeedlotTransportData = () => {
  const { userDataInput } = useContext(UserDataInputContext);
  if (!userDataInput || isEmptyObject(userDataInput)) {
    throw new Error('No active context for user inputs');
  }
  return userDataInput.data?.feedlot_transport;
};

export const useOtherData = () => {
  const { userDataInput } = useContext(UserDataInputContext);
  if (!userDataInput || isEmptyObject(userDataInput)) {
    throw new Error('No active context for user inputs');
  }
  return userDataInput.data?.other;
};

export const useProducerInfo = () => {
  const { userDataInput } = useContext(UserDataInputContext);
  if (!userDataInput || isEmptyObject(userDataInput)) {
    throw new Error('No active context for user inputs');
  }
  const {
    is_beef_producer: isBeefProducer,
    is_sheep_producer: isSheepProducer,
    is_crop_producer: isCropProducer,
    is_goat_producer: isGoatProducer,
    is_feedlot_producer: isFeedlotProducer
  } = userDataInput.data.property;
  return {
    isBeefProducer,
    isCropProducer,
    isSheepProducer,
    isGoatProducer,
    isFeedlotProducer
  };
};

export const useEnergyData = () => {
  const { userDataInput } = useContext(UserDataInputContext);
  if (!userDataInput || isEmptyObject(userDataInput)) {
    throw new Error('No active context for user inputs');
  }
  const { electricity, fuel_diesel, fuel_petrol } = userDataInput.data;
  return { electricity, fuel_diesel, fuel_petrol };
  // return { electricity, limestone, diesel: fuel_diesel, petrol: fuel_petrol };
};
export const useOffFarmData = () => {
  const { userDataInput } = useContext(UserDataInputContext);
  if (!userDataInput || isEmptyObject(userDataInput)) {
    throw new Error('No active context for user inputs');
  }
  const {
    off_farm_cotton_seed,
    off_farm_glyphosate,
    off_farm_grain,
    off_farm_hay,
    off_farm_mineral,
    off_farm_herb_pest,
    off_farm_single_superphosphate,
    off_farm_limestone,
    off_farm_fertiliser
  } = userDataInput.data;
  return {
    off_farm_cotton_seed,
    off_farm_glyphosate,
    off_farm_grain,
    off_farm_hay,
    off_farm_mineral,
    off_farm_herb_pest,
    off_farm_single_superphosphate,
    off_farm_limestone,
    off_farm_fertiliser
  };
};

export default UserDataInputContext;
