import axios from 'axios';

/**
 * Axios instance for all `carbon-calculator` api requests.
 *
 */
const CarbonCalculatorApi = axios.create({
  baseURL: process.env.REACT_APP_API,
  responseType: 'json'
});

// Add the token interceptor
// carbonCalculatorApi.interceptors.request.use(tokenInterceptor);
// carbonCalculatorApi.interceptors.response.use((response) => response, authInterceptor);

export default CarbonCalculatorApi;
