export interface InputContextI {
  userDataInput: UserDataInputState;
  UpdateUserDataInput: (userDataInput: any) => void;
  updateFullUserDataInput: (newUserDataInput: any) => void;
  addGroupInput: (targetGroup: keyof GroupData, groupData: GroupInput) => void;
  deleteGroupInput: (
    targetGroup: keyof GroupData,
    groupData: GroupInput
  ) => void;
  getGroupInput: (
    targetGroup: keyof GroupData,
    groupType: string
  ) => GroupInput<any> | undefined;
  /**
   * Save the usage, and proportional usage of a resource
   * @param key Key of the resource to save against in the data inputs context
   * @param data Information to save against the given key
   */
  saveProportion: (
    key: keyof OffFarmData | keyof EnergyData,
    data: ProportionData
  ) => void;
  /**
   * Get the existing proportion data for a specified key
   * @param key Key of the resource to fetch
   * @returns The current data stored under the key
   */
  getProportion: (key: keyof OffFarmData | keyof EnergyData) => ProportionData;
  saveProperty: (propertyData: Partial<PropertyData>) => void;
  saveYear: (year: string) => void;
  saveVegetation: (vegetationData: VegetationData, index?: number) => void;
  /**
   * Delete a vegetation entry by index of the array
   * @param index Index in the array of vegetation to delete
   */
  deleteVegetation: (index: number) => void;
  saveCrop: (cropGroup: CropGroup, index?: number) => void;
  /**
   * Delete a Crop entry by index of the array
   * @param index Index in the array of Crop to delete
   */
  deleteCrop: (index: number) => void;
  saveInputMode: (
    mode: 'seasonally' | 'monthly',
    animal: 'sheep' | 'beef' | 'goat' | 'feedlot'
  ) => void;
  saveSavannaBurning: (savannaBurningData: SavannaBurningData) => void;
  saveFeedlotTransport: (data: FeedlotTransportInputs) => void;
  saveOtherData: (data: OtherData) => void;
}

export const seasons = ['summer', 'autumn', 'winter', 'spring'] as const;
export type Season = typeof seasons[number];
export const months = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november'
] as const;
export type Month = typeof months[number];

export interface CropData {
  id: string;
  name: string;
  avg_grain_yield: number;
  area_sown: number;
  map: number;
  dap: number;
  soa: number;
  urea: number;
  single_superphosphate: number;
  burnt_fraction: number;
  non_urea_nitrogen: number;
  phosphorus: number;
  potassium: number;
  sulfur: number;
  uan: number;
}
export interface CropGroup {
  type: string;
  name: string;
  production_system: string;
  orange_zone: boolean;
  data: CropData[];
}

/**
 * Output format of group data
 */
export interface GroupInput<D = any> {
  record_id?: number;
  type: string;
  name: string;
  tooltip?: string;
  wool?: {
    shorn: number;
    wool_shorn: number;
    clean_wool_yield: number;
  };
  is_calving?: boolean;
  is_calf?: boolean;
  is_lambing?: boolean;
  is_lamb?: boolean;
  data: D[];
  /** Used for livestock inventory so we can allow users to input data monthly */
  data_monthly?: D[];
  // todo add feedlot
}
export type BeefData = {
  id: Season | Month | 'all';
  count: number;
  live_weight: number;
  live_weight_gain: number;
  calving_perc?: number;
  calf_perc?: number;
};
export type SheepData = {
  id: Season | Month | 'all';
  count: number;
  live_weight: number;
  live_weight_gain: number;
  lambing_perc?: number;
  lamb_perc?: number;
};

export type GoatData = {
  id: Season | Month | 'all';
  count: number;
  is_kid: boolean;
  is_kidding: number;
  kidding_perc?: number;
};

export interface GroupData {
  beef_livestock_groups: GroupInput<BeefData>[];
  beef_purchased_groups: GroupInput[];
  beef_sale_groups: GroupInput[];
  feedlot_livestock_groups: GroupInput[];
  feedlot_feed_composition_groups: GroupInput[];
  feedlot_purchased_groups: GroupInput<BeefData>[];
  sheep_livestock_groups: GroupInput<SheepData>[];
  sheep_purchased_groups: GroupInput[];
  sheep_sale_groups: GroupInput[];
  sheep_wool_groups: GroupInput[];
  goat_livestock_groups: GroupInput<GoatData>[];
  goat_purchased_groups: GroupInput[];
  goat_sale_groups: GroupInput[];
  goat_wool_groups: GroupInput[];
}

export interface ProportionData extends Record<string, any> {
  proportion: Record<string, number>;
}

export interface OffFarmData {
  off_farm_grain: ProportionData;
  off_farm_cotton_seed: ProportionData;
  off_farm_hay: ProportionData;
  off_farm_mineral: ProportionData;
  off_farm_single_superphosphate: ProportionData;
  off_farm_glyphosate: ProportionData;
  off_farm_herb_pest: ProportionData;
  off_farm_limestone: ProportionData;
  off_farm_fertiliser: ProportionData;
}

export interface OtherData {
  soil_carbon: number;
  remnant_vegetation: number;
}

export interface EnergyData {
  electricity: ProportionData;
  fuel_diesel: ProportionData;
  fuel_petrol: ProportionData;
}

export interface SavannaBurningData {
  rainfall: number;
  fire_season: number;
  fuel_class_size: number;
  patchiness: number;
  vegetation_class: number;
  years_since_fire: number;
  fire_scar_area: number;
}

export enum StateCodeE {
  NSW = '1_ACT/NSW',
  TAS = '3_TAS',
  SW_WA = '4_SOUTH_WEST',
  NW_WA = '9_NW_WA',
  SA = '5_SA',
  VIC = '6_VIC',
  QLD = '7_QLD',
  NT = '8_NT',
  AUS = '0_AUSTRALIA'
}

export enum FeedlotType {
  Drylot = 'Drylot',
  SolidStorage = 'Solid Storage',
  Composting = 'Composting',
  UncoveredAnaerobicLagoon = 'Uncovered anaerobic lagoon'
}

export enum TruckType {
  FourDeckTrailer = '1_four_deck_trailer',
  SixDeckTrailer = '2_six_deck_trailer',
  BDouble = '3_b_double'
}

export interface PropertyData {
  name: string;
  pic: string;
  beef_north_of_capricorn: boolean;
  sheep_north_of_capricorn: boolean;
  goat_north_of_capricorn: boolean;
  beef_orange_zone: boolean;
  sheep_orange_zone: boolean;
  goat_orange_zone: boolean;
  is_beef_producer: boolean;
  is_feedlot_producer: boolean;
  is_sheep_producer: boolean;
  is_crop_producer: boolean;
  is_goat_producer: boolean;
  beef_state: StateCodeE | '';
  feedlot_state: StateCodeE | '';
  sheep_state: StateCodeE | '';
  crop_state: StateCodeE | '';
  goat_state: StateCodeE | '';
  feedlot_type: FeedlotType | '';
}

export interface VegetationData {
  // id: string;
  name: string;
  state: string;
  region: string;
  soil: string;
  species: string;
  data: {
    id: string;
    name: string;
    surface: number;
    age: number;
    code: string;
    proportion: Record<string, number>;
  }[];
}

export interface FeedlotTransportInputs {
  kilometres: number;
  truck_type: TruckType;
}

export interface UserDataInputState {
  year: string;
  date: string;
  _id?: string;
  uid?: string;
  sheep_input_mode: 'monthly' | 'seasonally';
  beef_input_mode: 'monthly' | 'seasonally';
  goat_input_mode: 'monthly' | 'seasonally';
  version: number;
  methodology_version: number;
  data: {
    property: PropertyData;
    vegetation: VegetationData[];
    beef_savannah_burning: SavannaBurningData;
    crop_groups: CropGroup[];
    feedlot_transport: FeedlotTransportInputs;
    other: OtherData;
  } & GroupData &
    EnergyData &
    OffFarmData;
}

export interface UserDataInputItem {
  year: string;
  date: string;
  _id: string;
  version: number;
  methodology_version: number;
  data: {
    property: {
      name: string;
    };
  };
  beefTotal?: string;
  feedlotTotal?: string;
  sheepTotal?: string;
  goatTotal?: string;
  cropTotal?: string;
  total?: string;
}

// represents the structure of the data sent to updateUserDataInput()
export type UserDataInputExcel = {
  property: PropertyData;
  crop_groups: CropGroup[];
  vegetation: VegetationData[];
  beef_savannah_burning: SavannaBurningData;
  feedlot_transport: FeedlotTransportInputs;
} & GroupData &
  OffFarmData &
  EnergyData;

/**
 * REDUCER TYPES
 */

export enum InputActionE {
  UpdateUserDataInput = 'UpdateUserDataInput',
  UpdateFullUserDataInput = 'UpdateFullUserDataInput',
  AddGroup = 'AddGroup',
  DeleteGroup = 'DeleteGroup',
  SaveProportion = 'SaveProportion',
  SaveProperty = 'SaveProperty',
  SaveStaticFieldString = 'SaveStaticFieldString',
  SaveStaticFieldNumber = 'SaveStaticFieldNumber',
  SaveVegetation = 'SaveVegetation',
  DeleteVegetation = 'DeleteVegetation',
  SaveCrop = 'SaveCrop',
  DeleteCrop = 'DeleteCrop',
  SaveSavannaBurning = 'SaveSavannaBurning',
  ToggleInputMode = 'ToggleInputMode',
  SaveFeedlotTransport = 'SaveFeedlotTransport',
  SaveOtherData = 'SaveOtherData'
}

export type InputReducerAction =
  | {
      type: InputActionE.UpdateUserDataInput;
      payload: {
        userDataInput: any; // todo type ?
      };
    }
  | {
      type: InputActionE.UpdateFullUserDataInput;
      payload: {
        newUserDataInput: any;
      };
    }
  | {
      type: InputActionE.AddGroup;
      payload: {
        targetGroup: keyof GroupData;
        groupData: GroupInput;
      };
    }
  | {
      type: InputActionE.DeleteGroup;
      payload: {
        targetGroup: keyof GroupData;
        groupData: GroupInput;
      };
    }
  | {
      type: InputActionE.SaveProportion;
      payload: {
        resourceKey: keyof OffFarmData | keyof EnergyData;
        data: ProportionData;
      };
    }
  | {
      type: InputActionE.SaveProperty;
      payload: Partial<PropertyData>;
    }
  | {
      type: InputActionE.SaveSavannaBurning;
      payload: SavannaBurningData;
    }
  | {
      type: InputActionE.SaveVegetation;
      payload: {
        index: number | undefined;
        data: VegetationData;
      };
    }
  | {
      type: InputActionE.DeleteVegetation;
      payload: {
        index: number;
      };
    }
  | {
      type: InputActionE.SaveCrop;
      payload: {
        index: number | undefined;
        data: CropGroup;
      };
    }
  | {
      type: InputActionE.DeleteCrop;
      payload: {
        index: number;
      };
    }
  | {
      type: InputActionE.SaveStaticFieldString;
      payload: StaticField<'year' | 'date'>;
    }
  | {
      type: InputActionE.SaveStaticFieldNumber;
      payload: StaticField<'version' | 'methodology_version'>;
    }
  | {
      type: InputActionE.ToggleInputMode;
      payload: { mode: 'monthly' | 'seasonally'; animal: 'sheep' | 'beef' };
    }
  | {
      type: InputActionE.SaveFeedlotTransport;
      payload: FeedlotTransportInputs;
    }
  | {
  type: InputActionE.SaveOtherData;
  payload: OtherData;
};

export type StaticField<
  T extends 'year' | 'date' | 'version' | 'methodology_version'
> = {
  field: T;
  data: UserDataInputState[T];
};
