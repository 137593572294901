import {
  GroupInput,
  InputActionE,
  InputReducerAction,
  seasons,
  UserDataInputState
} from './userDataInputTypes';
import inputDefault from '../../assets/user_data_inputs_default.json';
import produce from 'immer';
import { getMonthsBySeason } from 'src/utils';

/**
 *
 *
 * INPUT REDUCER
 *
 *
 */

export const defaultInputDataState: UserDataInputState =
  inputDefault as UserDataInputState;

/**
 * Will remove a specified item from an array if it exists. Can optionally
 * supply a condition to check for the item rather
 * @param arr
 * @param value
 * @param condition
 * @returns the array without the item
 */
function removeItem<T, ArrayLike extends Array<T>>(
  arr: ArrayLike,
  value: T,
  condition?: (object: T) => boolean
) {
  let index: number;
  if (condition !== undefined) {
    index = arr.findIndex(condition);
  } else {
    index = arr.indexOf(value);
  }
  if (index > -1) {
    arr.splice(index, 1);
  }
}

/**
 * Input reducer implemented using immer's `produce` function.
 * Data can be modified directly, and produce will return the entire modified state.
 * No need to return anything manually from the produce function.
 */
export const inputReducer = produce(
  (draft: UserDataInputState, action: InputReducerAction) => {
    switch (action.type) {
      case InputActionE.UpdateUserDataInput:
        draft.data = action.payload.userDataInput;
        break;
      case InputActionE.UpdateFullUserDataInput:
        Object.assign(draft, action.payload.newUserDataInput);
        break;
      case InputActionE.AddGroup: {
        const { groupData, targetGroup } = action.payload;
        const target = draft.data[targetGroup];
        if (Array.isArray(target)) {
          removeItem(target, groupData, (group) => {
            if (group.record_id) {
              // there can be multiple rows of the same livestock group (eg: bulls>1).
              // in such cases, use record_id to determine which record to delete
              return group.record_id === groupData.record_id;
            }
            return group.type === groupData.type;
          });
          target.push(groupData);
        }
        break;
      }
      case InputActionE.DeleteGroup: {
        const { targetGroup, groupData } = action.payload;
        const target = draft.data[targetGroup];

        if (Array.isArray(target)) {
          removeItem(target, groupData, (group) => {
            if (group.record_id) {
              // there can be multiple rows of the same livestock group (eg: bulls>1).
              // in such cases, use record_id to determine which record to delete
              return group.record_id === groupData.record_id;
            }
            return group.type === groupData.type;
          });
        }
        break;
      }
      case InputActionE.SaveProportion: {
        const { resourceKey, data } = action.payload;
        draft.data[resourceKey] = data;
        break;
      }
      case InputActionE.SaveProperty: {
        draft.data.property = { ...draft.data.property, ...action.payload };
        break;
      }
      case InputActionE.SaveStaticFieldString: {
        draft[action.payload.field] = action.payload.data;
        break;
      }
      case InputActionE.SaveStaticFieldNumber: {
        draft[action.payload.field] = action.payload.data;
        break;
      }
      case InputActionE.SaveVegetation: {
        if (action.payload.index === undefined) {
          draft.data.vegetation.push(action.payload.data);
        } else {
          draft.data.vegetation[action.payload.index] = action.payload.data;
        }
        break;
      }
      case InputActionE.DeleteVegetation: {
        draft.data.vegetation.splice(action.payload.index, 1);
        break;
      }
      case InputActionE.SaveCrop: {
        if (action.payload.index === undefined) {
          draft.data.crop_groups.push(action.payload.data);
        } else {
          draft.data.crop_groups[action.payload.index] = action.payload.data;
        }
        break;
      }
      case InputActionE.DeleteCrop: {
        draft.data.crop_groups.splice(action.payload.index, 1);
        break;
      }
      case InputActionE.SaveSavannaBurning: {
        draft.data.beef_savannah_burning = action.payload;
        break;
      }
      case InputActionE.ToggleInputMode: {
        const { mode, animal } = action.payload;
        draft[`${animal}_input_mode`] = mode;

        if (mode === 'monthly') {
          // Toggle to montly mode
          const mapValues = (group: GroupInput) => {
            // Map each season to its corresponding months
            // Prefill the months with the value for the season
            const monthly = seasons
              .map((season) => {
                const seasonalData = group.data.find(({ id }) => id === season);
                return getMonthsBySeason(season).map((month) => ({
                  id: month,
                  ...Object.fromEntries(
                    Object.keys(seasonalData)
                      .filter((key) => key !== 'id')
                      .map((key) => [key, seasonalData[key]])
                  )
                }));
              })
              .flat() as any;
            group.data_monthly = monthly;
          };
          // Only applicable to livestock groups.
          draft.data[`${animal}_livestock_groups`].forEach(mapValues);
        } else if (mode === 'seasonally') {
          // Toggle to seasonal mode, dont need to do anything because we save the seasonal data at the same time as the monthly data
          // i.e. it should already be populated if there is monthly data.
        }
        break;
      }
      case InputActionE.SaveFeedlotTransport: {
        draft.data.feedlot_transport = {
          ...draft.data.feedlot_transport,
          ...action.payload
        };
        break;
      }
      case InputActionE.SaveOtherData: {
        draft.data.other = {
          ...draft.data.other,
          ...action.payload
        };
        break;
      }
      default:
        break;
    }
  }
);
