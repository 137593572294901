import {
  GroupInput,
  PropertyData,
  Season
} from 'src/contexts/UserDataInputContext/userDataInputTypes';

/**
 * Check if an object is empty
 * @param object Object to check
 * @returns truthy if the object is `{}`
 */
export const isEmptyObject = (object: Object) =>
  JSON.stringify(object) === '{}';

/**
 * Map options labels to their value to help display readable labels instead of values.
 * @param array Array of options used for multi-options form inputs such as radio groups, and select.
 * @returns An object of the options labels mapped to their value
 */
export const convertOptionsArrayToMap = (
  array: { label: string; value: string | number }[]
) => Object.fromEntries(array.map(({ label, value }) => [value, label]));

/** Capitalise a string */
export const capitalise = (word: string) =>
  word[0].toUpperCase() + word.slice(1);

/**
 * Get the valid groups to use as options for questions throughout the form process based
 * on what the user has selected they produce in the property information. Groups options include
 * `sheep`, `beef`, or `crop`.
 * @param property The property information for this calculation
 * @returns Array of possible groups based on what the user has selected
 */
export const getValidGroupsArray = (
  property: PropertyData
): { key: string; label: string }[] => {
  const {
    is_beef_producer,
    is_crop_producer,
    is_sheep_producer,
    is_goat_producer,
    is_feedlot_producer
  } = property;
  return [
    ...(is_beef_producer ? [{ key: 'beef', label: 'Beef' }] : []),
    ...(is_sheep_producer ? [{ key: 'sheep', label: 'Sheep' }] : []),
    ...(is_crop_producer ? [{ key: 'crop', label: 'Crop' }] : []),
    ...(is_goat_producer ? [{ key: 'goat', label: 'Goat' }] : []),
    ...(is_feedlot_producer ? [{ key: 'feedlot', label: 'Feedlot' }] : [])
  ];
};

const seasonalMonths = {
  summer: ['december', 'january', 'february'],
  autumn: ['march', 'april', 'may'],
  winter: ['june', 'july', 'august'],
  spring: ['september', 'october', 'november']
};
export const getMonthsBySeason = (season: Season) => {
  return seasonalMonths[season];
};

/**
 * Calculate seasonal values for Sheep and Beef livestock groups as the average value of the months in that season.
 * @param data The existing monthly data for beef or sheep livestock groups
 * @param season
 * @param field Field to calcuate an average for
 * @returns The average value across the specified season
 */
export const averageCountSeasonally = (
  data: GroupInput['data'],
  season: Season,
  field: string
) => {
  if (data.findIndex((d) => field in d) === -1) {
    return undefined;
  }
  return (
    data
      .filter((d: any) => getMonthsBySeason(season).includes(d.id))
      .reduce(
        (accumulator, currentValue) => accumulator + currentValue[field],
        0
      ) / 3
  );
};

/**
 * Generate an id to use in the local database store
 * @returns psuedorandom string
 */
export const generateLocalId = () => {
  return Math.random().toString(16).substring(2, 12);
};

export const precisionOrBlank = (result: number) => {
  return result
    ? parseFloat(
        result.toPrecision(
          result < 1 && result > -1 ? 1 : result >= 100000 ? 6 : result >= 10000 ? 5 : result >= 1000 ? 4 : 3
        )
      ).toString()
    : '';
};
