import {
  MethodologyAction,
  MethodologyActionE,
  MethodologyState,
} from './methodologyTypes';

export const defaultMethodologyState: MethodologyState = {
  methodology: {},
};

export const methodologyReducer = (state: any, action: MethodologyAction) => {
  let methodology: any;
  switch (action.type) {
    case MethodologyActionE.AddFormula:
      methodology = state.methodology;
      methodology.sections
        .find((s: any) => s.id === action.payload.sectionId)
        .formulas.push(action.payload.newFormula);
      return {
        ...state,
        ...methodology,
      };
    case MethodologyActionE.UpdateMethodology:
      methodology = action.payload.newMethodology;
      return {
        ...state,
        methodology: methodology,
      };
    case MethodologyActionE.UpdateFormula:
      methodology = state.methodology;
      methodology.sections.map((s: any) => {
        if (s.id === action.payload.sectionId) {
          s.formulas.map((f: any) => {
            if (f.id === action.payload.formulaId) {
              return action.payload.newFormula;
            } else return f;
          });
        } else return s;
      });
      return {
        ...state,
        ...methodology,
      };
    case MethodologyActionE.UpdateOutput:
      methodology = state.methodology;
      methodology.sections.map((s: any) => {
        if (s.id === action.payload.sectionId) {
          s.outputs.map((f: any) => {
            if (f.id === action.payload.outputId) {
              return action.payload.newOutput;
            } else return f;
          });
        } else return s;
      });
      return {
        ...state,
        ...methodology,
      };
    case MethodologyActionE.UpdateBackgroundData:
      methodology = state.methodology;
      methodology.background_data.map((bgd: any) => {
        if (bgd.id === action.payload.backgroundDataId) {
          return action.payload.newBackgroundData;
        } else return bgd;
      });
      return {
        ...state,
        ...methodology,
      };
    case MethodologyActionE.Reset:
      return defaultMethodologyState;
    default:
    // do something;
  }
};
