import {
  Chip,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Link } from 'react-router-dom';
import { ScopeItem } from '../../../../contexts/ResultContext/resultTypes';
import { useContext } from 'react';
import MethodologyContext from '../../../../contexts/MethodologyContext/methodologyContext';
import { precisionOrBlank } from '../../../../utils';

export interface ScopeTableProps {
  scopes: ScopeItem[],
  categories: string[],
  title: string
}

function ScopeTable({scopes, categories, title} : ScopeTableProps) {
  const { methodology } = useContext(MethodologyContext);

  const chipDisplay = (result: number) => {
    if (isNaN(result) || result === null)
      return <Chip onClick={() => null} variant={'outlined'} color={'warning'} icon={<ErrorOutlineIcon />} label="Error" />;
    return precisionOrBlank(result);
  }

  return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{title} Emissions<br/>t CO₂e/farm</TableCell>
            { categories.map((c) => <TableCell key={c} >{c}</TableCell>)}
            <TableCell>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {scopes.filter((s) => s.categories.indexOf('all') !== -1
            || s.categories.some(item => categories.includes(item)))
            .map((scope: any) => (
            <TableRow key={scope.id}>
              <TableCell><b>{scope.name}</b></TableCell>
              { categories.map((cat: string) => (
                <TableCell key={cat + scope.id}>{
                  scope.status === 'computed' ?
                    <Link
                      to={`../detail/${methodology.sections?.find((s) => s.reportable_outputs.find((r) => r.scope_id === scope.id && r.category === cat) !== undefined)?.id}`}
                      style={{ textDecoration: 'none', color: 'black' }}
                    >
                      { methodology.sections?.find((s) => s.reportable_outputs.find((r) => r.scope_id === scope.id && r.category === cat)) && chipDisplay(scopes.find((s) => s.id === scope.id)?.result?.find((r: any) => r.id === cat)?.result)}
                    </Link> :
                    scope.status === 'computing' ? <Skeleton variant='text' /> : <Skeleton variant='text' animation={false} />
                }</TableCell>
              )) }
              <TableCell>
                <b>{ scope.status === 'computed' ?
                  chipDisplay(scopes.find((s) => s.id === scope.id)?.result?.reduce((prev: number, r: any) => prev + (r.result || 0), 0)) :
                  scope.status === 'computing' ? <Skeleton variant='text' /> : <Skeleton variant='text' animation={false} /> }
                </b>
              </TableCell>
            </TableRow>
          ))}
          {/* TOTAL */}
          <TableRow>
            <TableCell><b>Total</b></TableCell>
            { categories.map((cat: string) => (
              <TableCell key={cat + 'Total'}>
                {scopes?.findIndex((item) => item.status === 'waiting') === -1 ? chipDisplay(scopes.reduce((prev: number, current: any) => prev + (current.result?.find((r: any) => r.id === cat)?.result || 0), 0)) : <Skeleton variant='text' animation={false} /> }
              </TableCell>
            )) }
            <TableCell>
              <b>
                {scopes?.findIndex((item) => item.status === 'waiting') === -1 ? chipDisplay(scopes.reduce((prev: number, current: any) => prev + (current.result?.reduce((p: number, c: any) => p + (c.result || 0), 0) || 0), 0)) : <Skeleton variant='text' animation={false} /> }
              </b>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
  );
}

export default ScopeTable;
