import { createContext, useCallback, useReducer } from 'react';

import {
  defaultCalculationState,
  calculationReducer,
} from './calculationReducers';

import {
  CalculationActionE, CalculationContextI, CalculationState, FormulaItem
} from './calculationTypes';

// react 18 as no more children in default React.Component
interface CalculationContextProps {
  children: React.ReactNode;
}

const defaultFunction = () => {
  throw new Error('Function not implemented');
};

const defaultCalculationContext: CalculationContextI = {
  UpdateFormula: defaultFunction,
  UpdateFullCalculation: defaultFunction,
  calculation: defaultCalculationState,
};
const CalculationContext = createContext<CalculationContextI>(defaultCalculationContext);

export const CalculationContextProvider: React.FC<CalculationContextProps> = ({ children }) => {
  const [state, dispatch] = useReducer(calculationReducer, defaultCalculationState);

  const UpdateFormula = useCallback((item: FormulaItem) => {
    dispatch({
      type: CalculationActionE.UpdateFormula,
      payload: { item },
    });
  }, []);

  const UpdateFullCalculation = useCallback((newCalculation: CalculationState) => {
    dispatch({
      type: CalculationActionE.UpdateFullCalculation,
      payload: { newCalculation },
    });
  }, []);

  return (
    <CalculationContext.Provider
      value={{
        UpdateFormula,
        UpdateFullCalculation,
        calculation: state,
      }}
    >
      {children}
    </CalculationContext.Provider>
  );
};

export default CalculationContext;
