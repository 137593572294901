import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from './layouts/SidebarLayout';
import BaseLayout from './layouts/BaseLayout';

import SuspenseLoader from './components/SuspenseLoader';
import { MethodologyContextProvider } from './contexts/MethodologyContext/methodologyContext';
import { ResultContextProvider } from './contexts/ResultContext/resultContext';
import { UserDataInputContextProvider } from './contexts/UserDataInputContext/userDataInputContext';
import { CalculationContextProvider } from './contexts/CalculationContext/calculationContext';
import { CallbackPage } from './content/callback-page';
import Integration from './content/Integration';
import CombineResults from './content/Calculation/Results/CombineResults';

export type RouteParams = {
  Calculation: { userdatainputid: string };
  Integration: { action: 'create' | 'modify' | 'manage' };
};

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Overview = Loader(lazy(() => import('./content/Overview')));

const AutoLogin = Loader(lazy(() => import('./content/AutoLogin')));

const PreviousDataPolicy = Loader(
  lazy(() => import('./content/PreviousDataPolicy'))
);

const Calculations = Loader(lazy(() => import('./content/Calculations')));

const Resources = Loader(lazy(() => import('./content/Resources')));

const Help = Loader(lazy(() => import('./content/Help')));

// Calculation

const Calculation = Loader(lazy(() => import('./content/Calculation')));

const Input = Loader(lazy(() => import('./content/Calculation/Input')));

const InputSummary = Loader(
  lazy(() => import('./content/Calculation/Input/InputSummary'))
);

const InputProperty = Loader(
  lazy(() => import('./content/Calculation/Input/InputProperty'))
);

const InputBeef = Loader(
  lazy(() => import('./content/Calculation/Input/InputBeef'))
);

const InputBeefFeedlot = Loader(
  lazy(() => import('./content/Calculation/Input/InputBeefFeedlot'))
);

const InputSheep = Loader(
  lazy(() => import('./content/Calculation/Input/InputSheep'))
);
const InputCrop = Loader(
  lazy(() => import('./content/Calculation/Input/InputCrop'))
);

const InputEnergy = Loader(
  lazy(() => import('./content/Calculation/Input/InputEnergy'))
);

const InputVegetation = Loader(
  lazy(() => import('./content/Calculation/Input/InputVegetation'))
);

const InputOffFarm = Loader(
  lazy(() => import('./content/Calculation/Input/InputOffFarm'))
);

const InputGoat = Loader(
  lazy(() => import('./content/Calculation/Input/InputGoat'))
);

const InputTransport = Loader(
  lazy(() => import('./content/Calculation/Input/InputTransport'))
);

const InputSoilRemnant = Loader(
  lazy(() => import('./content/Calculation/Input/InputSoilRemnant'))
);

const Detail = Loader(lazy(() => import('./content/Calculation/Detail')));

const Results = Loader(lazy(() => import('./content/Calculation/Results')));

// User
const UserSettings = Loader(lazy(() => import('./content/Users/Settings')));

// Admin
const Admin = Loader(lazy(() => import('./content/Admin')));

// Status page
const Status404 = Loader(lazy(() => import('./content/Status/Status404')));
const Status500 = Loader(lazy(() => import('./content/Status/Status500')));
const StatusComingSoon = Loader(
  lazy(() => import('./content/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('./content/Status/Maintenance'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Overview />
      },
      {
        path: '/autologin/',
        element: <AutoLogin />
      },
      {
        path: '/previous-data-policy',
        element: <PreviousDataPolicy />
      },
      {
        path: '/callback',
        element: <CallbackPage />
      },
      {
        path: 'overview',
        element: <Navigate to="/" replace />
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'dashboard',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="calculations" replace />
      },
      {
        path: 'calculations',
        element: <Calculations />
      },
      {
        path: 'resources',
        element: <Resources />
      },
      {
        path: 'help',
        element: <Help />
      }
    ]
  },
  {
    path: 'integration/:action',
    element: <Integration />
  },
  {
    path: 'calculation/:userdatainputid',
    element: <SidebarLayout />,
    children: [
      {
        path: '', // Default to results page
        element: <Navigate to="input" replace />
      },
      {
        path: '*',
        element: (
          <MethodologyContextProvider>
            <ResultContextProvider>
              <UserDataInputContextProvider>
                <Calculation />
              </UserDataInputContextProvider>
            </ResultContextProvider>
          </MethodologyContextProvider>
        ),
        children: [
          {
            path: 'input',
            element: <Input />,
            children: [
              {
                path: '',
                element: <InputSummary />
              },
              {
                path: 'property',
                element: <InputProperty />
              },
              {
                path: 'beef',
                element: <InputBeef />
              },
              {
                path: 'beef-feedlot',
                element: <InputBeefFeedlot />
              },
              {
                path: 'sheep',
                element: <InputSheep />
              },
              {
                path: 'goat',
                element: <InputGoat />
              },
              {
                path: 'crop',
                element: <InputCrop />
              },
              {
                path: 'energy',
                element: <InputEnergy />
              },
              {
                path: 'vegetation',
                element: <InputVegetation />
              },
              {
                path: 'off-farm',
                element: <InputOffFarm />
              },
              {
                path: 'transport',
                element: <InputTransport />
              },
              {
                path: 'soil-remnant-vegetation',
                element: <InputSoilRemnant />
              }
            ]
          },
          {
            path: 'detail',
            element: <Navigate to="overview" replace />
          },
          {
            path: 'detail/:step',
            element: (
              <CalculationContextProvider>
                <Detail />
              </CalculationContextProvider>
            )
          },
          {
            path: 'results',
            element: <Navigate to="./results-table" replace />
          },
          {
            path: 'results/:tab',
            element: <Results />
          },
          {
            path: 'combine-results',
            element: <Navigate to="./results-table" replace />
          },
          {
            path: 'combine-results/:tab',
            element: <CombineResults />
          }
        ]
      }
    ]
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="profile/settings" replace />
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Navigate to="settings" replace />
          },
          {
            path: 'settings',
            element: <UserSettings />
          }
        ]
      }
    ]
  },
  {
    path: 'admin',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: (
          <MethodologyContextProvider>
            <Admin />
          </MethodologyContextProvider>
        )
      }
    ]
  }
];

export default routes;
