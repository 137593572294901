export interface MethodologyContextI {
  methodology: any;
  UpdateMethodology: (methodology: any) => void;
  AddFormula: (sectionId: string, newFormula: any) => void;
  UpdateFormula: (sectionId: string, formulaId: string, newFormula: any) => void;
  UpdateOutput: (sectionId: string, outputId: string, newOutput: any) => void;
  UpdateBackgroundData: (backgroundDataId: string, newBackgroundData: any) => void;
}

/**
 *
 *
 * METHODOLOGY TYPES
 *
 *
 */
export interface MethodologyState {
  methodology: any;
}

export enum MethodologyActionE {
  AddFormula = 'AddFormula',
  UpdateMethodology = 'UpdateMethodology',
  UpdateFormula = 'UpdateFormula',
  UpdateOutput = 'UpdateOutput',
  UpdateBackgroundData = 'UpdateBackgroundData',
  Reset = 'Reset',
}

export type MethodologyAction =
  | {
      type: MethodologyActionE.AddFormula;
      payload: { sectionId: string; newFormula: any };
    }
  | {
      type: MethodologyActionE.UpdateMethodology;
      payload: { newMethodology: any };
    }
  | {
      type: MethodologyActionE.UpdateFormula;
      payload: { sectionId: string; formulaId: string; newFormula: any };
    }
  | {
      type: MethodologyActionE.UpdateOutput;
      payload: { sectionId: string; outputId: string; newOutput: any };
    }
  | {
      type: MethodologyActionE.UpdateBackgroundData;
      payload: { backgroundDataId: string; newBackgroundData: any };
    }
  | { type: MethodologyActionE.Reset }; // reset to default
