import {
  Box,
  Card,
  Grid,
  Typography,
  styled
} from '@mui/material';
import { useContext } from 'react';
import MethodologyContext from '../../../../contexts/MethodologyContext/methodologyContext';
import { precisionOrBlank } from '../../../../utils';
import { Methodology } from '../../../../api/carbon-calculator-api-types';
import { ResultState } from '../../../../contexts/ResultContext/resultTypes';

export interface ScopeTableProps {
  result: ResultState;
  categories: string[]
}

export const getEmissionIntensity = (result: ResultState, methodology: Methodology, category: string, type: string, sequestration: boolean) => {
  return precisionOrBlank(
    ((result?.scope_1.reduce(
          (prev: number, current: any) =>
            prev +
            (current.result?.find((r: any) => r.id === category)?.result || 0),
          0
        ) +
        result?.scope_2.reduce(
          (prev: number, current: any) =>
            prev +
            (current.result?.find((r: any) => r.id === category)?.result ||
              0),
          0
        ) +
        result?.scope_3.reduce(
          (prev: number, current: any) =>
            prev +
            (current.result?.find((r: any) => r.id === category)?.result ||
              0),
          0
        ) +
        (sequestration ? result?.sequestration.reduce(
          (prev: number, current: any) =>
            prev +
            (current.result?.find((r: any) => r.id === category)?.result ||
              0),
          0
        ) : 0)) *
      (category === 'crop' ? 1 : 1000) * //Crop is in tonnes already
      (category === 'sheep' || category === 'goat' ? // if Sheep or Goat compute sale allocation
        (type === 'wool' ?
          result?.sale_inventory
            ?.find((si: any) => si.id === `si_clean_wool`)
            ?.result?.find((r: any) => r.id === category)?.result /
          ((methodology.background_data?.find(
              (bg: any) => bg.id === 'sale_allocation_factor'
            ).data[category].protein * result?.sale_inventory
            ?.find((si: any) => si.id === `si_meat`)
            ?.result?.find((r: any) => r.id === category)?.result) +
            result?.sale_inventory
              ?.find((si: any) => si.id === `si_clean_wool`)
              ?.result?.find((r: any) => r.id === category)?.result)
          :
            (methodology.background_data?.find(
              (bg: any) => bg.id === 'sale_allocation_factor'
            ).data[category].protein * result?.sale_inventory
              ?.find((si: any) => si.id === `si_meat`)
              ?.result?.find((r: any) => r.id === category)?.result)  /
            ((methodology.background_data?.find(
                (bg: any) => bg.id === 'sale_allocation_factor'
              ).data[category].protein * result?.sale_inventory
                ?.find((si: any) => si.id === `si_meat`)
                ?.result?.find((r: any) => r.id === category)?.result) +
              result?.sale_inventory
                ?.find((si: any) => si.id === `si_clean_wool`)
                ?.result?.find((r: any) => r.id === category)?.result)
        )
        : methodology.background_data?.find(
        (bg: any) => bg.id === 'sale_allocation_factor'
      ).data[category][type])) /
    result?.sale_inventory
      ?.find((si: any) => si.id === `si_${type}`)
      ?.result?.find((r: any) => r.id === category)?.result
  )
}

const CardLogo = styled('img')(
  ({ theme }) => `
      border: 1px solid ${theme.colors.alpha.black[30]};
      border-radius: ${theme.general.borderRadius};
      padding: ${theme.spacing(1)};
      margin-right: ${theme.spacing(2)};
      background: ${theme.colors.alpha.white[100]};
`
);
const CardCc = styled(Card)(
  ({ theme }) => `
     border: 1px solid ${theme.colors.alpha.black[30]};
     background: ${theme.colors.alpha.black[5]};
     box-shadow: none;
     background: ${theme.colors.alpha.white[100]};
`
);

function EmissionsIntensity({ result, categories } : ScopeTableProps) {

  const { methodology } = useContext(MethodologyContext);

  return (
    <Grid container spacing={3} >
      { (categories.indexOf('beef') !== -1 &&
        result?.sale_inventory?.find((si: any) => si.id === 'si_meat')?.result?.find((r: any) => r.id === 'beef')?.result) ?
        <Grid item xs={12} sm={6}>
          <CardCc sx={{ px: 2, pt: 2, pb: 1 }}>
            <Box display="flex" alignItems="center">
              <CardLogo
                src="/static/images/avatars/beef-meat.svg"
                alt="Beef-Meat"
              />
              <Box>
                <Typography variant="h4" fontWeight="normal">
                  Beef Meat
                </Typography>
                <Typography variant="h3" fontWeight="bold">
                  {getEmissionIntensity(result, methodology, 'beef', 'meat', true)}{' '}
                  <Typography component="span" color="text.primary">kg co₂e / kg LW</Typography>
                </Typography>
                <Typography variant="subtitle2" >
                  <Typography component="span" fontWeight="bold" color="text.primary">
                    {getEmissionIntensity(result, methodology, 'beef', 'meat', false)}
                  </Typography>
                  {' '}(Excl sq)

                </Typography>
              </Box>
            </Box>
          </CardCc>
        </Grid> : null
      }
      { (categories.indexOf('feedlot') !== -1 &&
        result?.sale_inventory?.find((si: any) => si.id === 'si_meat')?.result?.find((r: any) => r.id === 'feedlot')?.result) ?
        <Grid item xs={12} sm={6}>
          <CardCc sx={{ px: 2, pt: 2, pb: 1 }}>
            <Box display="flex" alignItems="center">
              <CardLogo
                src="/static/images/avatars/feedlot-meat.svg"
                alt="Feedlot-Meat"
              />
              <Box>
                <Typography variant="h4" fontWeight="normal">
                  Feedlot Meat
                </Typography>
                <Typography variant="h3" fontWeight="bold">
                  {getEmissionIntensity(result, methodology, 'feedlot', 'meat', true)}{' '}
                  <Typography component="span" color="text.primary">kg co₂e / kg LW</Typography>
                </Typography>
                <Typography variant="subtitle2" >
                  <Typography component="span" fontWeight="bold" color="text.primary">
                    {getEmissionIntensity(result, methodology, 'feedlot', 'meat', false)}
                  </Typography>
                  {' '}(Excl sq)
                </Typography>
              </Box>
            </Box>
          </CardCc>
        </Grid> : null
      }
      { (categories.indexOf('sheep') !== -1 && result?.sale_inventory?.find((si: any) => si.id === 'si_meat')?.result?.find((r: any) => r.id === 'sheep')?.result) ?
        <Grid item xs={12} sm={6}>
        <CardCc sx={{
          px: 2,
          pt: 2,
          pb: 1
        }}>
          <Box display="flex" alignItems="center">
            <CardLogo
              src="/static/images/avatars/sheep-meat.svg"
              alt="Sheep-Meat"
            />
            <Box>
              <Typography variant="h4" fontWeight="normal">
                Sheep Meat
              </Typography>
              <Typography variant="h3" fontWeight="bold">
                {getEmissionIntensity(result, methodology, 'sheep', 'meat', true)}{' '}
                <Typography component="span" color="text.primary">kg co₂e / kg LW</Typography>
              </Typography>
              <Typography variant="subtitle2">
                <Typography component="span" fontWeight="bold" color="text.primary">
                  {getEmissionIntensity(result, methodology, 'sheep', 'meat', false)}
                </Typography>
                {' '}(Excl sq)

              </Typography>
            </Box>
          </Box>
        </CardCc>
      </Grid> : null
      }
      { (categories.indexOf('goat') !== -1 && result?.sale_inventory?.find((si: any) => si.id === 'si_meat')?.result?.find((r: any) => r.id === 'goat')?.result) ?
        <Grid item xs={12} sm={6}>
          <CardCc sx={{
            px: 2,
            pt: 2,
            pb: 1
          }}>
            <Box display="flex" alignItems="center">
              <CardLogo
                src="/static/images/avatars/goat-meat.svg"
                alt="Goat-Meat"
              />
              <Box>
                <Typography variant="h4" fontWeight="normal">
                  Goat Meat
                </Typography>
                <Typography variant="h3" fontWeight="bold">
                  {getEmissionIntensity(result, methodology, 'goat', 'meat', true)}{' '}
                  <Typography component="span" color="text.primary">kg co₂e / kg LW</Typography>
                </Typography>
                <Typography variant="subtitle2">
                  <Typography component="span" fontWeight="bold" color="text.primary">
                    {getEmissionIntensity(result, methodology, 'goat', 'meat', false)}
                  </Typography>
                  {' '}(Excl sq)

                </Typography>
              </Box>
            </Box>
          </CardCc>
        </Grid> : null
      }
      { (categories.indexOf('sheep') !== -1 && result?.sale_inventory?.find((si: any) => si.id === 'si_wool')?.result?.find((r: any) => r.id === 'sheep')?.result) ?
        <Grid item xs={12} sm={6}>
        <CardCc sx={{ px: 2, pt: 2, pb: 1 }}>
          <Box display="flex" alignItems="center">
            <CardLogo
              src="/static/images/avatars/sheep-wool.svg"
              alt="Sheep-Wool"
            />
            <Box>
              <Typography variant="h4" fontWeight="normal">
                Sheep Wool
              </Typography>
              <Typography variant="h3" fontWeight="bold">
                {getEmissionIntensity(result, methodology, 'sheep', 'wool', true)}{' '}{' '}
                <Typography component="span" color="text.primary">kg co₂e / kg Greasy</Typography>
              </Typography>
              <Typography variant="subtitle2" >
                <Typography component="span" fontWeight="bold" color="text.primary">
                  {getEmissionIntensity(result, methodology, 'sheep', 'wool', false)}{' '}
                </Typography>
                {' '}(Excl sq)

              </Typography>
            </Box>
          </Box>
        </CardCc>
      </Grid> : null
      }
      { (categories.indexOf('goat') !== -1 && result?.sale_inventory?.find((si: any) => si.id === 'si_wool')?.result?.find((r: any) => r.id === 'goat')?.result) ?
        <Grid item xs={12} sm={6}>
          <CardCc sx={{ px: 2, pt: 2, pb: 1 }}>
            <Box display="flex" alignItems="center">
              <CardLogo
                src="/static/images/avatars/goat-wool.svg"
                alt="Goat-Wool"
              />
              <Box>
                <Typography variant="h4" fontWeight="normal">
                  Goat Wool
                </Typography>
                <Typography variant="h3" fontWeight="bold">
                  {getEmissionIntensity(result, methodology, 'goat', 'wool', true)}{' '}{' '}
                  <Typography component="span" color="text.primary">kg co₂e / kg Greasy</Typography>
                </Typography>
                <Typography variant="subtitle2" >
                  <Typography component="span" fontWeight="bold" color="text.primary">
                    {getEmissionIntensity(result, methodology, 'goat', 'wool', true)}{' '}
                  </Typography>
                  {' '}(Excl sq)
                </Typography>
              </Box>
            </Box>
          </CardCc>
        </Grid> : null
      }
      { (categories.indexOf('crop') !== -1 &&
        result?.sale_inventory?.find((si: any) => si.id === 'si_crop')?.result?.find((r: any) => r.id === 'crop')?.result) ?
        <Grid item xs={12} sm={6}>
        <CardCc sx={{ px: 2, pt: 2, pb: 1 }}>
          <Box display="flex" alignItems="center">
            <CardLogo
              src="/static/images/avatars/crop-production.svg"
              alt="Crop-Production"
            />
            <Box>
              <Typography variant="h4" fontWeight="normal">
                Crop Production
              </Typography>
              <Typography variant="h3" fontWeight="bold">
                {getEmissionIntensity(result, methodology, 'crop', 'crop', true)}{' '}{' '}
                <Typography component="span" color="text.primary">t co₂e / t</Typography>
              </Typography>
              <Typography variant="subtitle2" >
                <Typography component="span" fontWeight="bold" color="text.primary">
                  {getEmissionIntensity(result, methodology, 'crop', 'crop', false)}
                </Typography>
                {' '}(Excl sq)

              </Typography>
            </Box>
          </Box>
        </CardCc>
      </Grid> : null
      }
    </Grid>
  );
}

export default EmissionsIntensity;
