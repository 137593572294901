import CarbonCalculatorApi from '../carbon-calculator-api';
import { Methodology } from '../carbon-calculator-api-types';

/**
 * Handles all requests to the `/run` resource.
 */
class MethodologyApi {
  private resource = 'methodology';

  /**
   * Gets
   */
  getMethodologiesList = async (token: string, controller?: AbortController) => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await CarbonCalculatorApi.get(`/${this.resource}/list`, {
      params: {},
      signal: controller?.signal,
      headers,
    });
    return response.data as Methodology[];
  };

  /**
   * Get a Methodology by id
   */
  getMethodology = async (methodologyId: string, token: string, controller?: AbortController) => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await CarbonCalculatorApi.get(`/${this.resource}/${methodologyId}`, {
      params: {},
      signal: controller?.signal,
      headers,
    });
    return response.data as Methodology;
  };

  /**
   * Get a Methodology by version
   */
  getMethodologyByVersion = async (version: string, controller?: AbortController) => {
    const response = await CarbonCalculatorApi.get(`/${this.resource}/${version}`, {
      params: {},
      signal: controller?.signal,
    });
    return response.data as Methodology;
  };

  /**
   * Get last Methodology
   */
  getLastMethodology = async (controller?: AbortController) => {
    const response = await CarbonCalculatorApi.get(`/${this.resource}/last`, {
      params: {},
      signal: controller?.signal,
    });
    return response.data as Methodology;
  };

  /**
   * Create Methodology
   */
  createMethodology = async (
    methodology: Methodology,
    token: string,
    controller?: AbortController
  ) => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const m = { ...methodology };
    delete m.released;
    delete m._id;
    const response = await CarbonCalculatorApi.post(`/${this.resource}`, m, {
      signal: controller?.signal,
      headers,
    });
    return response.data.id as string;
  };

  /**
   * Update Methodology
   */
  updateMethodology = async (
    methodology: Methodology,
    token: string,
    controller?: AbortController
  ) => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const m = { ...methodology };
    delete m.released;
    delete m._id;
    const response = await CarbonCalculatorApi.post(
      `/${this.resource}/${methodology._id}`,
      m,
      {
        signal: controller?.signal,
        headers,
      }
    );
    return response.data as boolean;
  };

  /**
   * Release Methodology
   */
  releaseMethodology = async (
    methodologyId: string,
    token: string,
    controller?: AbortController
  ) => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await CarbonCalculatorApi.put(
      `/${this.resource}/${methodologyId}/release_status`,
      undefined,
      {
        params: {},
        signal: controller?.signal,
        headers,
      }
    );
    return response.data as boolean;
  };

  /**
   * Release Methodology
   */
  unreleaseMethodology = async (
    methodologyId: string,
    token: string,
    controller?: AbortController
  ) => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await CarbonCalculatorApi.put(
      `/${this.resource}/${methodologyId}/unrelease_status`,
      undefined,
      {
        params: {},
        signal: controller?.signal,
        headers,
      }
    );
    return response.data as boolean;
  };

  /**
   * Delete Methodology
   */
  deleteMethodology = async (
    methodologyId: string,
    token: string,
    controller?: AbortController
  ) => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await CarbonCalculatorApi.delete(`/${this.resource}/${methodologyId}`, {
      params: {},
      signal: controller?.signal,
      headers,
    });
    return response.data as boolean;
  };
}

export default new MethodologyApi();
